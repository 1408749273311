.panel-primary-care-1col {
  .ui-tabs {
    width: 100%;
    font-family: "Open Sans",sans-serif;
    font-size: 15px;
    margin-top: -5px;
    > .item-list {
      position: relative;
      &:after {
        content: '';
        background: #ddd;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        z-index: 0;
      }
      > ul {
        padding: 5px  0 0 0;
        margin: 1rem 0;
        // border-radius: 0px !important;
        li {
          float: left;
          list-style: outside none none;
          padding: 0 !important;
          margin-bottom: 0 !important;
          z-index: 1;
        }
        a {
          display: inline-block;
          font-size: 12px;
          color: #CF4520;
          padding: 1rem;
          border-bottom: 1px solid #ddd !important;
          outline: none;
          
          //@include transition(all linear 0.15s);
          //@include breakpoint($sm) {
            font-size: 13px;
            padding: 1.3rem 2rem;
          //}
          &:hover {
            //border-color: #CF4520 !important;
            //@include transition(all linear 0.25s);
            //@include box-shadow(0, 1px, 4px, rgba(207, 69, 32, 1));
            text-decoration: none;
          }
        }
        li.ui-tabs-active {
          background: #fff;
          a {
            color: #777;
            background: #fff;
            border-radius: 5px 5px 0px 0px;
            border-color: #ddd !important;
            border-width: 1px 1px 0 1px !important;
            border-style: solid !important;
            padding: 1.3rem 2rem 1.3rem 2rem !important;
          }
        }
        li.ui-state-default {
          background: #FFF;
          padding: 1rem;
          border-width: 0;
        }
        li.ui-state-hover {
          // all: initial !important;
          padding: 1rem;
          border-width: 0;
        }
      }
    }
    .ui-widget-header {
      background: #FFF !important;
      font-family: inherit !important;
      font-size: inherit !important;
    }
    .ui-widget-content a {
      color: #CF4520;
      &:hover {
        color: #E87722;
      }
    }
    .ui-tabs-panel {
      padding: 1.2rem 0 !important;
      .view-calendar {
        .view-header {
          .date-nav {
            ul.pager {
              li.date-prev,
              li.date-next {
                background: none;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  .pane-calendar-panel-pane-1,
  .pane-calendar-panel-pane-2 {
    padding-bottom: 2rem;
    .view-header {
      position: relative;
      .date-result-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: initial;
        bottom: -10.5rem;
        @include breakpoint($sm) {
          right: 5%;
          top: 0.5rem;
          bottom: initial;
        }
        .date-result-message {
          margin: 0 auto;
          font-size: 1rem;
          @include breakpoint($sm) {
            font-size: 1.4rem;
            padding-left: 3rem;
          }
        }
      }
      .date-nav-wrapper {
        position: inherit;
        margin-top: 1.2rem;
        .date-nav {
          .date-heading {
            text-align: left;
            width: 40%;
            h3 {
              font-size: 1.5rem;
              @include breakpoint($sm) {
                font-size: 2rem;
              }
            }
          }
          .pager {
            li {
              padding: 0 1rem;
              width: 10rem;
              a {
                color: #fff;
                background-color: #cf4520;
                border-color: #cf4520;
                width: 10rem;
                &:hover {
                  background-color: #E87722;
                  color: #fff;
                }
              }
            }
            li.date-prev {
              margin-right: 6rem;
            }
          }
        }
      }
    }
    .view-filters {
      position: relative;
      form {
        clear: both;
        padding-bottom: 5rem;
        margin-top: -2rem;
        @include breakpoint($sm) {
          position: absolute;
          bottom: 7.5rem;
          right: 0;
          margin: 0;
        }
        .views-exposed-widget {
          padding: 0;
          label {
            float: left;
            padding: 1rem 1.5rem 0 0;
          }
          .views-widget {
            float: left;
          }
        }
      }
      .select2 {
        width: 32rem !important;
      }
    }
    .view-content {
      .calendar-calendar {
        font-size: 1rem;
        letter-spacing: -1.8px;
        @include breakpoint($sm) {
          font-size: 1.3rem;
          letter-spacing: inherit;
        }
        th.days {
          background-color: transparent;
          border: none;
          padding: 1rem 0;
          letter-spacing: 0px;
        }
        .week-view,
        .month-view {
          min-width: 55rem;
          thead {
            border-top: 7px solid #ececec;
          }
          .inner {
            font-size: 1.6rem;
            color: #555;
            .view-item {
              border-left: 5px solid #eab62d;
              background-color: #fffed7;
              min-height: 10rem;
              .calendar {
                padding: 8px;
                background: none;
                font-size: medium;
                overflow: visible;
                .views-field {
                  font-size: 1.1rem;
                  line-height: 1.3;
                  @include breakpoint($sm) {
                    font-size: 1.4rem;
                  }
                  .field-content {
                    .date-display-single {
                      font-weight: 100;
                    }
                  }
                }
                .contents {
                  font-size: 1.1rem;
                  line-height: 1.3;
                  position: inherit;
                  width: auto;
                  background-color: #fffed7;
                  left: 0;
                  @include breakpoint($sm) {
                    font-size: 1.4rem;
                  }
                  // .date-display-single {
                  //   font-weight: 100;
                  //   color: #555;
                  //   display: block;
                  // }
                }
              }
            }
          }
          .cutoff {
            display: none;
          }
        }
        .week-view {
          th.calendar-day-holder {
            border: none;
          }
          th.calendar-agenda-hour {
            border-bottom: none;
          }
          tr.all-day {
            height: 5rem;
            .calendar-agenda-hour {
              color: #555;
              border-color: #dcdcdc;
              border-left: 1px solid #dcdcdc;
              .calendar-hour {
                font-size: 1rem;
                font-weight: normal;
                padding-right: 2px;
                letter-spacing: 0;
              }
            }
            td.multi-day {
              padding: 4px 0;
              width: 6%;
              .date-display-single {
                display: none;
              }
              a {
                font-weight: 200;
                letter-spacing: 0;
              }
            }
          }
          tr.not-all-day {
            border-right: 1px solid #ececec;
            height: 5rem;
            &:last-child {
              border-bottom: 1px solid #dcdcdc;
            }
            td {
              color: #555;
              border-color: #dcdcdc;
              letter-spacing: 0;
              padding: 0;
              width: 6%;
              &.calendar-agenda-hour {
                color: #555;
                border-color: #dcdcdc;
                border-left: 1px solid #dcdcdc;
                .calendar-hour,
                .calendar-ampm {
                  font-size: 1rem;
                  font-weight: normal;
                  padding-right: 2px;
                }
              }
              .calendar {
                padding: 0;
                padding-bottom: 3px;
                .item {
                  .weekview {
                    margin: 0.4rem auto;
                  }
                }
              }
              a {
                font-weight: 200;
              }
            }
          }
          .full {
            div.single-day {
              div.weekview {
                border: none;
              }
            }
          }
        }
        .month-view {
          tr {
            &.multi-day,
            &.single-day {
              .inner {
                min-height: 9rem;
              }
              .empty {
                .inner {
                  background-color: #fdfdfd;
                }
              }
            }
            &:last-child {
              border-bottom: 1px solid #dcdcdc;
            }
            td {
              letter-spacing: -0.4px;
              color: #555;
              border-color: #dcdcdc;
              border-style: solid;
              border-width: 1px 1px 0 1px;
              padding: 0;
              &.today {
                border-color: #e7781f;
                border-width: 0 4px 4px 4px;
              }
              &.multi-day {
                padding-top: 3px;
                .date-display-single {
                  display: none;
                }
              }
              &.multi-day,
              &.single-day {
                border-top: 0px !important;
                &.empty {
                  background: #fff;
                  color: #ddd;
                }
              }
              a {
                font-weight: 200;
              }
            }
          }
          td.date-box {
            height: 4rem;
            padding-top: 4px;
            &.empty {
              background-color: #fdfdfd;
              //background: #fff;
              .inner {
                color: #eee;
              }
            }
            &.today {
              border-color: #e7781f;
              border-width: 4px 4px 0 4px;
            }
          }
        }
      }
    }
  }
  .pane-calendar-panel-pane-1 {
    .view-header {
      .date-result-wrapper {
        right: 5%;
        .date-result-message {
          width: 100%;
          @include breakpoint($sm) {
            width: 50%;
          }
        }
      }
    }
  }
  .pane-calendar-panel-pane-2 {
    .view-header {
      .date-result-wrapper {
        right: 0;
        .date-result-message {
          width: 100%;
          @include breakpoint($sm) {
            width: 40%;
          }
        }
      }
    }
  }
  .ajax-progress-throbber {
    display: none;
  }
  table.table {
    tr {
      th {
        font-size: 1.1rem;
        vertical-align: middle;
        text-align: center;
        padding: 4px;
        @include breakpoint($sm) {
          font-size: 1.3rem;
        }
        @include breakpoint($md) {
          font-size: 1.4rem;
        }
      }
      td {
        font-size: 1.1rem;
        @include breakpoint($sm) {
          font-size: 1.3rem;
        }
        @include breakpoint($md) {
          font-size: 1.4rem;
        }
      }
    }
  }
}
