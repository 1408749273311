.no-js {
  .panel-primary-care-home {
    .go-to-next {
      display: none;
    }
    #primary-care-header {
      .front-hero-container {
        .container-block {
          cursor: default;
          .slide-item {
            .slide-text {
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    #primary-care-footer {
      .right-bottom-left {
        height: 56rem !important;
        @include breakpoint($md) {
          height: 41rem !important;
        }
        @include breakpoint($lg) {
          height: 56rem !important;
        }
        iframe {
          background-image: url("../images/primary_care_img_maps.jpg");
          background-size: 100% 100%;
        }
      }
      .right-bottom-right {
        height: 56rem !important;
        @include breakpoint($md) {
          height: 41rem !important;
        }
        @include breakpoint($lg) {
          height: 56rem !important;
        }
      }
    }
  }

  #main-content {
    .pane-views-panes {
      .view-filters {
        display: none !important;
      }
    }
  }
}
