/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/**
 *
 */
.panel-primary-care-home #primary-care-header {
  background-color: #f5f5f5; }
  .panel-primary-care-home #primary-care-header .container-fluid {
    padding: 0; }
  .panel-primary-care-home #primary-care-header .front-hero-container {
    margin: 0 auto; }
    .panel-primary-care-home #primary-care-header .front-hero-container .container-block {
      padding: 0;
      cursor: pointer; }
      .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students,
      .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals,
      .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients {
        position: relative;
        height: 40rem;
        z-index: 1; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students:after,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals:after,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -25%;
          margin: 0 auto;
          border-top: 0rem solid transparent;
          border-right: 18rem solid transparent;
          border-bottom: 5rem solid transparent;
          border-left: 18rem solid transparent;
          height: 10rem;
          width: 34rem; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-image-layover,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-image-layover,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-image-layover {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0.35;
          z-index: 0; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-layover,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-layover,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-layover {
          position: absolute;
          position: absolute;
          height: 12.1rem;
          width: 100%;
          bottom: 0;
          opacity: .85;
          z-index: 2; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-text,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-text,
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-text {
          position: absolute;
          width: 100%;
          font-family: '1898Sans-Regular';
          font-weight: 600;
          font-size: 3.8rem;
          padding-top: 2rem;
          margin: 2.2rem auto 0 auto;
          top: 69%;
          text-align: center;
          color: #fff;
          background: none;
          opacity: 1;
          z-index: 3; }
          .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-text:before,
          .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-text:before,
          .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-text:before {
            content: '';
            position: absolute;
            border-radius: 50%;
            background: url(../images/sprite/sprite.svg) no-repeat;
            overflow: hidden;
            bottom: 69%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 13rem;
            height: 13rem;
            border-style: solid; }
          .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-text:after,
          .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-text:after,
          .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-text:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            bottom: 69%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 13rem;
            height: 13rem; }
      .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students {
        background-image: url("/sites/default/files/front_students.jpg");
        background-repeat: no-repeat;
        background-position: 50%, 0%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-color: #e36000;
        background-blend-mode: multiply; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students:after {
          border-top-color: #cf4520; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-layover {
          background-color: #cf4520; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-text:before {
          background-color: #cf4520;
          border-color: #cf4520;
          border-width: 3px;
          background-size: 166rem;
          background-position: -1450px -189px; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students .slide-text:after {
          -webkit-transition: transform 0.2s, opacity 0.2s;
          -moz-transition: transform 0.2s, opacity 0.2s;
          -ms-transition: transform 0.2s, opacity 0.2s;
          -o-transition: transform 0.2s, opacity 0.2s;
          transition: transform 0.2s, opacity 0.2s;
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
          -o-transform: scale(0.8);
          transform: scale(0.8); }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students:hover .slide-text:before {
          background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
          background-color: #fff;
          border-color: #cf4520;
          border-width: 6px;
          background-size: 162rem;
          background-position: -1416px -186px; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-students:hover .slide-text:after {
          box-shadow: 0px 0px 0px 5px #fff;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1);
          opacity: 1; }
      .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals {
        background-image: url("/sites/default/files/front_professionals.jpg");
        background-repeat: no-repeat;
        background-position: 50%, 0%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-color: #f3bd00;
        background-blend-mode: multiply; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals:after {
          border-top-color: #ffc82d; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-layover {
          background-color: #ffc82d; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-text:before {
          background-color: #ffc82d;
          border-color: #ffc82d;
          border-width: 3px;
          background-size: 166rem;
          background-position: -505px -189px; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals .slide-text:after {
          -webkit-transition: transform 0.2s, opacity 0.2s;
          -moz-transition: transform 0.2s, opacity 0.2s;
          -ms-transition: transform 0.2s, opacity 0.2s;
          -o-transition: transform 0.2s, opacity 0.2s;
          transition: transform 0.2s, opacity 0.2s;
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
          -o-transform: scale(0.8);
          transform: scale(0.8); }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals:hover .slide-text:before {
          background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
          background-color: #fff;
          border-color: #ffc82d;
          border-width: 6px;
          background-size: 162rem;
          background-position: -494px -186px; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-professionals:hover .slide-text:after {
          box-shadow: 0px 0px 0px 5px #fff;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1);
          opacity: 1; }
      .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients {
        background-image: url("/sites/default/files/front_patients.jpg");
        background-repeat: no-repeat;
        background-position: 50%, 0%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -ms-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-color: #f26f00;
        background-blend-mode: multiply; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients:after {
          border-top-color: #e7751d; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-layover {
          background-color: #e7751d; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-text:before {
          background-color: #e7751d;
          border-color: #e7751d;
          border-width: 3px;
          background-size: 166rem;
          background-position: -1345px -298px; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients .slide-text:after {
          -webkit-transition: transform 0.2s, opacity 0.2s;
          -moz-transition: transform 0.2s, opacity 0.2s;
          -ms-transition: transform 0.2s, opacity 0.2s;
          -o-transition: transform 0.2s, opacity 0.2s;
          transition: transform 0.2s, opacity 0.2s;
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
          -o-transform: scale(0.8);
          transform: scale(0.8); }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients:hover .slide-text:before {
          background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
          background-color: #fff;
          border-color: #e7751d;
          border-width: 6px;
          background-size: 162rem;
          background-position: -1315px -291px; }
        .panel-primary-care-home #primary-care-header .front-hero-container .container-block .container-slide-patients:hover .slide-text:after {
          box-shadow: 0px 0px 0px 5px #fff;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1);
          opacity: 1; }
  @media screen and (min-width: 992px) {
    .panel-primary-care-home #primary-care-header .front-hero-container.mobile {
      display: none; } }
  .panel-primary-care-home #primary-care-header .front-hero-container.desktop {
    display: none; }
    @media screen and (min-width: 992px) {
      .panel-primary-care-home #primary-care-header .front-hero-container.desktop {
        display: block; } }

.panel-primary-care-home .pane-front-hero-pane {
  clear: both; }
  .panel-primary-care-home .pane-front-hero-pane .pane-title {
    font-family: '1898Sans-Regular', sans-serif;
    font-size: 8.2rem;
    font-weight: 800;
    color: #ddd;
    text-align: left;
    padding: 2.4rem 0; }
  @media screen and (min-width: 992px) {
    .panel-primary-care-home .pane-front-hero-pane .front-header-pane-container.slidedown {
      max-height: 52rem;
      height: 52rem; } }

.panel-primary-care-home #header_pane_students,
.panel-primary-care-home #header_pane_professionals,
.panel-primary-care-home #header_pane_patients {
  position: relative; }
  .panel-primary-care-home #header_pane_students .front-header-pane-title,
  .panel-primary-care-home #header_pane_professionals .front-header-pane-title,
  .panel-primary-care-home #header_pane_patients .front-header-pane-title {
    font-size: 6rem;
    font-family: '1898Sans-Bold';
    color: #cdcdcd;
    text-align: center;
    padding: 3.5rem 0 0 0; }
    @media screen and (min-width: 992px) {
      .panel-primary-care-home #header_pane_students .front-header-pane-title,
      .panel-primary-care-home #header_pane_professionals .front-header-pane-title,
      .panel-primary-care-home #header_pane_patients .front-header-pane-title {
        font-size: 8rem;
        padding: 2.5rem 0 0 0; } }
  .panel-primary-care-home #header_pane_students .front-header-pane-subtitle,
  .panel-primary-care-home #header_pane_professionals .front-header-pane-subtitle,
  .panel-primary-care-home #header_pane_patients .front-header-pane-subtitle {
    text-align: center;
    font-size: 2.4rem;
    margin-top: -4rem;
    color: #777;
    font-weight: 100; }
  .panel-primary-care-home #header_pane_students .front-header-pane-body,
  .panel-primary-care-home #header_pane_professionals .front-header-pane-body,
  .panel-primary-care-home #header_pane_patients .front-header-pane-body {
    width: 100%;
    margin: 0 auto;
    padding: 1.5rem 0 5.5rem 0; }
  .panel-primary-care-home #header_pane_students form#available-funding-form .form-item,
  .panel-primary-care-home #header_pane_students form#search-research-study-form .form-item,
  .panel-primary-care-home #header_pane_professionals form#available-funding-form .form-item,
  .panel-primary-care-home #header_pane_professionals form#search-research-study-form .form-item,
  .panel-primary-care-home #header_pane_patients form#available-funding-form .form-item,
  .panel-primary-care-home #header_pane_patients form#search-research-study-form .form-item {
    margin-bottom: 2rem; }
    .panel-primary-care-home #header_pane_students form#available-funding-form .form-item label,
    .panel-primary-care-home #header_pane_students form#search-research-study-form .form-item label,
    .panel-primary-care-home #header_pane_professionals form#available-funding-form .form-item label,
    .panel-primary-care-home #header_pane_professionals form#search-research-study-form .form-item label,
    .panel-primary-care-home #header_pane_patients form#available-funding-form .form-item label,
    .panel-primary-care-home #header_pane_patients form#search-research-study-form .form-item label {
      display: block;
      font-size: 1.6rem;
      color: #777;
      font-weight: 400;
      padding-top: 1.5rem; }
  .panel-primary-care-home #header_pane_students form#available-funding-form .select2,
  .panel-primary-care-home #header_pane_students form#search-research-study-form .select2,
  .panel-primary-care-home #header_pane_professionals form#available-funding-form .select2,
  .panel-primary-care-home #header_pane_professionals form#search-research-study-form .select2,
  .panel-primary-care-home #header_pane_patients form#available-funding-form .select2,
  .panel-primary-care-home #header_pane_patients form#search-research-study-form .select2 {
    width: 30rem !important;
    text-align: left; }
    @media screen and (min-width: 768px) {
      .panel-primary-care-home #header_pane_students form#available-funding-form .select2,
      .panel-primary-care-home #header_pane_students form#search-research-study-form .select2,
      .panel-primary-care-home #header_pane_professionals form#available-funding-form .select2,
      .panel-primary-care-home #header_pane_professionals form#search-research-study-form .select2,
      .panel-primary-care-home #header_pane_patients form#available-funding-form .select2,
      .panel-primary-care-home #header_pane_patients form#search-research-study-form .select2 {
        width: 36rem !important; } }
    .panel-primary-care-home #header_pane_students form#available-funding-form .select2 .select2-selection--single,
    .panel-primary-care-home #header_pane_students form#search-research-study-form .select2 .select2-selection--single,
    .panel-primary-care-home #header_pane_professionals form#available-funding-form .select2 .select2-selection--single,
    .panel-primary-care-home #header_pane_professionals form#search-research-study-form .select2 .select2-selection--single,
    .panel-primary-care-home #header_pane_patients form#available-funding-form .select2 .select2-selection--single,
    .panel-primary-care-home #header_pane_patients form#search-research-study-form .select2 .select2-selection--single {
      height: 50px !important; }
      .panel-primary-care-home #header_pane_students form#available-funding-form .select2 .select2-selection--single .select2-selection__rendered,
      .panel-primary-care-home #header_pane_students form#search-research-study-form .select2 .select2-selection--single .select2-selection__rendered,
      .panel-primary-care-home #header_pane_professionals form#available-funding-form .select2 .select2-selection--single .select2-selection__rendered,
      .panel-primary-care-home #header_pane_professionals form#search-research-study-form .select2 .select2-selection--single .select2-selection__rendered,
      .panel-primary-care-home #header_pane_patients form#available-funding-form .select2 .select2-selection--single .select2-selection__rendered,
      .panel-primary-care-home #header_pane_patients form#search-research-study-form .select2 .select2-selection--single .select2-selection__rendered {
        line-height: 50px !important;
        color: #777 !important; }
  .panel-primary-care-home #header_pane_students form#available-funding-form .search-more,
  .panel-primary-care-home #header_pane_students form#search-research-study-form .search-more,
  .panel-primary-care-home #header_pane_professionals form#available-funding-form .search-more,
  .panel-primary-care-home #header_pane_professionals form#search-research-study-form .search-more,
  .panel-primary-care-home #header_pane_patients form#available-funding-form .search-more,
  .panel-primary-care-home #header_pane_patients form#search-research-study-form .search-more {
    padding-top: 2rem; }

.panel-primary-care-home #header_pane_students div.front-page-content:not(:first-child),
.panel-primary-care-home #header_pane_professionals div.front-page-content:not(:first-child) {
  padding-top: 3rem; }
  @media screen and (min-width: 992px) {
    .panel-primary-care-home #header_pane_students div.front-page-content:not(:first-child),
    .panel-primary-care-home #header_pane_professionals div.front-page-content:not(:first-child) {
      padding-top: 0; } }

.panel-primary-care-home #header_pane_students div.front-page-content:first-child,
.panel-primary-care-home #header_pane_professionals div.front-page-content:first-child {
  border-right: 1px solid #e8e8e8; }

.panel-primary-care-home #header_pane_students div.front-page-content:last-child,
.panel-primary-care-home #header_pane_professionals div.front-page-content:last-child {
  margin: 0 auto; }

.panel-primary-care-home #header_pane_students div.front-page-content .front-page-content-title,
.panel-primary-care-home #header_pane_professionals div.front-page-content .front-page-content-title {
  font-size: 2.2rem;
  padding-top: 1rem;
  padding-bottom: 0.2rem;
  color: #777;
  font-weight: 600;
  padding-top: 0; }

.panel-primary-care-home #header_pane_students.slidedown {
  border-bottom: 5px solid #cf4520; }

.panel-primary-care-home #header_pane_students.slideup {
  border: none; }

.panel-primary-care-home #header_pane_students div.front-page-content:last-child {
  text-align: center; }

.panel-primary-care-home #header_pane_students .view .view-content .views-field-title a {
  font-size: 2rem; }

.panel-primary-care-home #header_pane_students .view .view-content .views-field-field-primary-investigator .views-label {
  font-weight: 600; }

.panel-primary-care-home #header_pane_students .view .view-content .views-field-field-primary-investigator .field-content {
  display: inline-block; }

.panel-primary-care-home #header_pane_students .view .view-content .views-field-field-research-study-area,
.panel-primary-care-home #header_pane_students .view .view-content .views-field-field-research-study-status {
  display: inline-block; }
  .panel-primary-care-home #header_pane_students .view .view-content .views-field-field-research-study-area .field-content a,
  .panel-primary-care-home #header_pane_students .view .view-content .views-field-field-research-study-status .field-content a {
    position: relative;
    color: #cf4520;
    font-size: 13px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px; }

.panel-primary-care-home #header_pane_students .view .view-content .views-field-body {
  padding-bottom: 1rem; }

.panel-primary-care-home #header_pane_students .view .view-content .views-field-field-research-study-status {
  padding-left: 2rem; }

.panel-primary-care-home #header_pane_professionals.slidedown {
  border-bottom: 5px solid #ffc82d; }

.panel-primary-care-home #header_pane_professionals.slideup {
  border: none; }

.panel-primary-care-home #header_pane_professionals div.front-page-content {
  text-align: center; }
  .panel-primary-care-home #header_pane_professionals div.front-page-content .professionals-content-container {
    font-size: 2.2rem;
    font-weight: 600;
    color: #777;
    width: 100%; }
    .panel-primary-care-home #header_pane_professionals div.front-page-content .professionals-content-container div.professionals-content {
      width: 78%;
      margin: 0 auto;
      line-height: 1.4; }
      .panel-primary-care-home #header_pane_professionals div.front-page-content .professionals-content-container div.professionals-content a {
        margin-top: 1rem; }
      .panel-primary-care-home #header_pane_professionals div.front-page-content .professionals-content-container div.professionals-content:nth-child(2) {
        position: relative;
        padding-top: 2.5rem; }
        .panel-primary-care-home #header_pane_professionals div.front-page-content .professionals-content-container div.professionals-content:nth-child(2):before {
          content: '';
          background: #eee;
          position: absolute;
          height: 1px;
          top: 18px;
          left: 0;
          right: 0;
          width: 60%;
          height: 1px;
          margin: 0 auto; }
    .panel-primary-care-home #header_pane_professionals div.front-page-content .professionals-content-container p {
      padding-bottom: 0.8rem; }

.panel-primary-care-home #header_pane_patients.slidedown {
  border-bottom: 5px solid #e7751d; }

.panel-primary-care-home #header_pane_patients.slideup {
  border: none; }

.panel-primary-care-home #header_pane_patients div.front-page-content {
  text-align: center;
  color: #777; }
  .panel-primary-care-home #header_pane_patients div.front-page-content:not(:first-child) {
    padding-top: 3rem; }
    @media screen and (min-width: 992px) {
      .panel-primary-care-home #header_pane_patients div.front-page-content:not(:first-child) {
        padding-top: 0; } }
  .panel-primary-care-home #header_pane_patients div.front-page-content:first-child, .panel-primary-care-home #header_pane_patients div.front-page-content:nth-child(2) {
    border-right: 1px solid #e8e8e8; }
  .panel-primary-care-home #header_pane_patients div.front-page-content:last-child {
    margin: 0 auto; }
  .panel-primary-care-home #header_pane_patients div.front-page-content .front-page-content-title {
    font-size: 2.2rem;
    font-weight: 600;
    width: 100%;
    height: 7rem;
    line-height: 1.4; }
  .panel-primary-care-home #header_pane_patients div.front-page-content .patients-content-container {
    font-size: 1.6rem;
    text-align: center; }
    .panel-primary-care-home #header_pane_patients div.front-page-content .patients-content-container div.patients-content {
      margin: 0 auto;
      width: 85%; }
      .panel-primary-care-home #header_pane_patients div.front-page-content .patients-content-container div.patients-content a {
        margin-top: 3rem; }
      .panel-primary-care-home #header_pane_patients div.front-page-content .patients-content-container div.patients-content a.external-link {
        padding: 10px 20px 10px 0px !important; }
        .panel-primary-care-home #header_pane_patients div.front-page-content .patients-content-container div.patients-content a.external-link:hover:before {
          opacity: 1;
          left: 70%;
          color: #fff; }
        .panel-primary-care-home #header_pane_patients div.front-page-content .patients-content-container div.patients-content a.external-link:before {
          left: 70%;
          opacity: 1;
          font-size: 1.5rem;
          line-height: 3;
          color: #e7751d; }

.panel-primary-care-home #primary-care-content-footer .pane-title,
.panel-primary-care-home .pane-calendar-panel-pane-3 .pane-title,
.panel-primary-care-home .pane-news-panel-pane-5 .pane-title {
  font-family: '1898Sans-Bold', sans-serif;
  font-size: 6rem;
  color: #ddd;
  text-align: left;
  padding: 2.4rem 0 0 0; }
  @media screen and (min-width: 992px) {
    .panel-primary-care-home #primary-care-content-footer .pane-title,
    .panel-primary-care-home .pane-calendar-panel-pane-3 .pane-title,
    .panel-primary-care-home .pane-news-panel-pane-5 .pane-title {
      font-size: 8rem; } }

.panel-primary-care-home #primary-care-content-footer .view-header,
.panel-primary-care-home .pane-calendar-panel-pane-3 .view-header,
.panel-primary-care-home .pane-news-panel-pane-5 .view-header {
  margin-top: 2rem; }

.panel-primary-care-home .primary-care-content-top {
  padding: 1.5rem 0 5.5rem 0rem;
  position: relative;
  background-color: #fff; }
  .panel-primary-care-home .primary-care-content-top:after {
    content: '';
    background: #e7751d;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 26%;
    height: 1px;
    margin: 0 auto; }
  .panel-primary-care-home .primary-care-content-top a.go-to-next {
    bottom: -21px; }

.panel-primary-care-home .pane-calendar-panel-pane-3 .pane-title {
  padding: 0 0 2rem 0; }

.panel-primary-care-home .pane-calendar-panel-pane-3 .view {
  position: relative; }
  .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-header {
    position: absolute;
    top: -8rem;
    right: 0; }
    @media screen and (min-width: 992px) {
      .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-header {
        top: -9rem; } }
    .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-header a {
      font-size: 1.3rem;
      color: #e7751d; }
      .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-header a:before {
        font-size: 1.3rem; }
      .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-header a .glyphicon-triangle-right:before {
        font-size: 1rem; }
  .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content {
    clear: both; }
    .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row {
      width: 90%;
      position: relative;
      text-align: center;
      padding-bottom: 2rem;
      margin: 0 auto; }
      @media screen and (min-width: 768px) {
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row {
          width: 50%; } }
      @media screen and (min-width: 992px) {
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row {
          width: 22.4%;
          float: left;
          margin-right: 3rem; } }
      @media screen and (min-width: 1200px) {
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row {
          width: 23%; } }
      @media screen and (min-width: 992px) {
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row.views-row-last {
          margin-right: 0; } }
      .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip {
        height: 38rem;
        transition: all 0.3s ease-in;
        transform-style: preserve-3d;
        -webkit-perspective: 1500px;
        /* Chrome, Safari, Opera */
        perspective: 1500px;
        font-size: 13px; }
        @media screen and (min-width: 1200px) {
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip {
            font-size: 15px; } }
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip:hover .front-event-flip-front {
          text-decoration: none; }
          @media screen and (min-width: 992px) {
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip:hover .front-event-flip-front {
              transform: rotateY(180deg); } }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip:hover .front-event-flip-front .field-event-title h4,
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip:hover .front-event-flip-front .field-event-category {
            text-decoration: none; }
        @media screen and (min-width: 992px) {
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip:hover .front-event-flip-back {
            transform: rotateY(0deg); } }
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front,
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back {
          width: 100%;
          height: 100%;
          transition: all 0.3s ease-in;
          transform-style: preserve-3d;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -ms-backface-visibility: hidden;
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0; }
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front {
          z-index: 2;
          transform: rotateY(0deg);
          background-color: #fff; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front a {
            outline: none; }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front a .field-event-title {
              min-height: 11rem; }
              .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front a .field-event-title h4 {
                color: #cf4520;
                line-height: 1.5;
                font-size: 2rem; }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front a:after {
              display: none; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front a[target=_blank] .field-event-title h4:after {
            font-size: 85%;
            padding-left: 5px;
            vertical-align: middle;
            line-height: 1.2;
            content: '\e811';
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-decoration: none; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-start-date {
            position: absolute;
            width: 8rem;
            top: 14%;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 50%;
            line-height: 0;
            background: transparent;
            color: #fff;
            border: 2px solid #fff;
            z-index: 2; }
            @media screen and (min-width: 1200px) {
              .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-start-date {
                top: 16.5%; } }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-start-date .event-start-date {
              display: inline-block;
              font-family: '1898Sans-Regular', sans-serif;
              font-size: 3.8rem;
              margin-top: 2.4rem;
              padding: 0.8rem 1.2rem 1.6rem 1.2rem; }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-start-date .event-start-month {
              font-size: 1.8rem;
              display: block;
              margin-bottom: 2.2rem;
              padding-top: 0.6rem; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-image {
            z-index: 1;
            overflow: hidden;
            vertical-align: middle;
            background-color: #cf4520;
            opacity: 1;
            border-radius: 5px;
            max-height: 18rem;
            height: 18rem;
            width: auto; }
            @media screen and (min-width: 1200px) {
              .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-image {
                height: 20rem;
                max-height: 20rem; } }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-image img {
              opacity: 0.3;
              min-height: 18rem;
              width: 100%;
              height: auto;
              z-index: 0; }
              @media screen and (min-width: 1200px) {
                .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-image img {
                  min-height: 20rem; } }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-title,
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-category {
            text-align: left; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-category {
            padding-top: 0;
            margin-top: 0.5rem;
            position: relative;
            color: #cf4520;
            font-size: 13px;
            background: url(../images/tag.png) 0 0 no-repeat;
            background-size: 16px 16px;
            padding-left: 25px; }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-front .field-event-category:hover {
              text-decoration: none; }
        .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back {
          z-index: 1;
          background-color: #f5f5f5;
          border-radius: 5px;
          text-align: left;
          padding: 2rem; }
          @media screen and (min-width: 992px) {
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back {
              transform: rotateY(180deg); } }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back > a {
            position: relative;
            color: #cf4520;
            font-size: 13px;
            background: url(../images/tag.png) 0 0 no-repeat;
            background-size: 16px 16px;
            padding-left: 25px; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-body {
            padding-bottom: 1rem;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-date,
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-time,
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-location {
            position: relative;
            padding-bottom: 1.5rem;
            padding-left: 2.9rem; }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-date:before,
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-time:before,
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-location:before {
              content: '';
              position: absolute;
              background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
              background-size: 50rem;
              width: 32px;
              height: 32px;
              left: -5px;
              top: -2px; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-date {
            margin-top: 2.5rem; }
            .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-date:before {
              background-position: -157px -30px; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-start-time:before {
            background-position: -409px -30px; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .field-event-location:before {
            background-position: -377px -30px; }
          .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .event-external-link {
            position: absolute;
            bottom: 3rem; }
            @media screen and (min-width: 1200px) {
              .panel-primary-care-home .pane-calendar-panel-pane-3 .view .view-content .views-row .front-event-flip .front-event-flip-back .event-external-link {
                bottom: 2rem; } }

.panel-primary-care-home .primary-care-content-body {
  background-color: #fcfcfc;
  padding-bottom: 6rem; }
  @media screen and (min-width: 992px) {
    .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .pane-title {
      white-space: nowrap; } }
  .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-header {
    position: relative; }
    .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-header a {
      position: absolute;
      bottom: 4rem;
      right: 0;
      font-size: 1.3rem;
      color: #e7751d; }
      @media screen and (min-width: 992px) {
        .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-header a {
          display: none; } }
      .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-header a:after {
        font-size: 1.3rem; }
      .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-header a .glyphicon-triangle-right:before {
        font-size: 1rem; }
  .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .teaser-image img {
    width: 100%; }
  .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .post-date {
    padding-top: 1.5rem;
    font-size: 1.3rem;
    color: #888; }
  .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .teaser-title {
    line-height: 1.3;
    font-family: '1898Sans-Bold'; }
    .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .teaser-title a {
      color: #cf4520; }
      .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .teaser-title a:hover {
        color: #e7751d; }
      .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .teaser-title a:after {
        content: ''; }
  .panel-primary-care-home .primary-care-content-body .pane-news-panel-pane-5 .view .view-teaser .views-field-field-news-category a {
    position: relative;
    color: #cf4520;
    font-size: 13px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px; }

.panel-primary-care-home #primary-care-content-footer {
  background-color: #fcfcfc;
  position: relative; }
  .panel-primary-care-home #primary-care-content-footer:before {
    content: '';
    background: #e7751d;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 26%;
    height: 1px;
    margin: 0 auto; }
  .panel-primary-care-home #primary-care-content-footer .pane-title {
    padding-bottom: 0;
    margin-bottom: -0.5rem; }
  .panel-primary-care-home #primary-care-content-footer a.go-to-next {
    top: -23px; }

.panel-primary-care-home #primary-care-footer {
  clear: both; }
  .panel-primary-care-home #primary-care-footer .container-fluid,
  .panel-primary-care-home #primary-care-footer .row,
  .panel-primary-care-home #primary-care-footer .col-md-12,
  .panel-primary-care-home #primary-care-footer .col-md-6,
  .panel-primary-care-home #primary-care-footer p {
    padding: 0;
    margin: 0; }
  .panel-primary-care-home #primary-care-footer .pane-front-footer-pane {
    max-width: 144rem;
    margin: 0 auto; }
    .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container {
      margin: 0;
      padding: 0;
      clear: both; }
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-top,
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-bottom-left,
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-bottom-right,
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-top-left,
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-top-right {
        overflow: hidden; }
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-top img,
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-bottom-left img,
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-bottom-right img,
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-top-left img,
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-top-right img {
          width: 100%;
          height: auto;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          margin-bottom: -1px; }
          .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-top img:hover,
          .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-bottom-left img:hover,
          .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .left-bottom-right img:hover,
          .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-top-left img:hover,
          .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-top-right img:hover {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1); }
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-container .right-bottom-left iframe {
        width: 100%;
        height: 100%; }
      .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-container .right-bottom-right {
        height: 100%;
        background: #cf4520;
        color: #fff;
        font-size: 1.8rem;
        font-weight: 100;
        line-height: 1.5;
        padding: 8rem 2.4rem;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-container .right-bottom-right {
            text-align: left;
            padding: 12rem 2.4rem; } }
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-container .right-bottom-right h2 {
          padding-bottom: 1rem;
          color: #fff; }
        .panel-primary-care-home #primary-care-footer .pane-front-footer-pane .front-footer-pane-container .right-container .right-bottom-right a {
          color: #fff; }

.panel-primary-care-home a.go-to-next {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-decoration: none;
  border-radius: 50%;
  padding: 2rem 1.65rem;
  line-height: 0;
  background: #fff;
  color: #fff;
  border: 2px solid #e7751d;
  width: 44px;
  z-index: 1;
  outline: none; }
  .panel-primary-care-home a.go-to-next:hover {
    background: #e7751d; }
    .panel-primary-care-home a.go-to-next:hover:after {
      color: #fff; }
  .panel-primary-care-home a.go-to-next:after {
    content: '\25bc';
    display: block;
    color: #e7751d;
    font-weight: bold;
    font-size: 8px;
    text-align: center;
    -webkit-transform: scale(1.8, 1);
    -moz-transform: scale(1.8, 1);
    -ms-transform: scale(1.8, 1);
    -o-transform: scale(1.8, 1);
    transform: scale(1.8, 1); }

#body-2col-right {
  padding: 0; }
  @media screen and (min-width: 992px) {
    #body-2col-right {
      padding: 0 2rem 0 2rem; } }
  #body-2col-right .pane-news-panel-pane-7 {
    padding-top: 1rem; }
    @media screen and (min-width: 992px) {
      #body-2col-right .pane-news-panel-pane-7 {
        padding-top: 10rem; } }
    #body-2col-right .pane-news-panel-pane-7 .view {
      position: relative; }
      #body-2col-right .pane-news-panel-pane-7 .view .view-header {
        position: absolute;
        top: 0;
        right: 0; }
        #body-2col-right .pane-news-panel-pane-7 .view .view-header a {
          font-size: 1.3rem;
          color: #e7751d;
          display: none; }
          @media screen and (min-width: 992px) {
            #body-2col-right .pane-news-panel-pane-7 .view .view-header a {
              display: block; } }
          #body-2col-right .pane-news-panel-pane-7 .view .view-header a:after {
            font-size: 1.3rem; }
          #body-2col-right .pane-news-panel-pane-7 .view .view-header a .glyphicon-triangle-right:before {
            font-size: 1rem; }
      #body-2col-right .pane-news-panel-pane-7 .view .view-content {
        clear: both;
        padding-top: 1rem; }
        @media screen and (min-width: 992px) {
          #body-2col-right .pane-news-panel-pane-7 .view .view-content {
            padding-top: 4.5rem; } }
        #body-2col-right .pane-news-panel-pane-7 .view .view-content .view-teaser {
          padding-bottom: 1rem; }
          #body-2col-right .pane-news-panel-pane-7 .view .view-content .view-teaser .post-date {
            font-size: 1.3rem;
            color: #888; }
          #body-2col-right .pane-news-panel-pane-7 .view .view-content .view-teaser .teaser-title {
            font-family: '1898Sans-Bold';
            line-height: 1.3; }
            #body-2col-right .pane-news-panel-pane-7 .view .view-content .view-teaser .teaser-title a {
              color: #cf4520; }
              #body-2col-right .pane-news-panel-pane-7 .view .view-content .view-teaser .teaser-title a:hover {
                color: #e7751d; }
              #body-2col-right .pane-news-panel-pane-7 .view .view-content .view-teaser .teaser-title a:after {
                content: ''; }
      #body-2col-right .pane-news-panel-pane-7 .view .views-field-field-news-category a {
        position: relative;
        color: #cf4520;
        font-size: 13px;
        background: url(../images/tag.png) 0 0 no-repeat;
        background-size: 16px 16px;
        padding-left: 25px; }

.panel-primary-care-1col .ui-tabs {
  width: 100%;
  font-family: "Open Sans",sans-serif;
  font-size: 15px;
  margin-top: -5px; }
  .panel-primary-care-1col .ui-tabs > .item-list {
    position: relative; }
    .panel-primary-care-1col .ui-tabs > .item-list:after {
      content: '';
      background: #ddd;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      z-index: 0; }
    .panel-primary-care-1col .ui-tabs > .item-list > ul {
      padding: 5px  0 0 0;
      margin: 1rem 0; }
      .panel-primary-care-1col .ui-tabs > .item-list > ul li {
        float: left;
        list-style: outside none none;
        padding: 0 !important;
        margin-bottom: 0 !important;
        z-index: 1; }
      .panel-primary-care-1col .ui-tabs > .item-list > ul a {
        display: inline-block;
        font-size: 12px;
        color: #CF4520;
        padding: 1rem;
        border-bottom: 1px solid #ddd !important;
        outline: none;
        font-size: 13px;
        padding: 1.3rem 2rem; }
        .panel-primary-care-1col .ui-tabs > .item-list > ul a:hover {
          text-decoration: none; }
      .panel-primary-care-1col .ui-tabs > .item-list > ul li.ui-tabs-active {
        background: #fff; }
        .panel-primary-care-1col .ui-tabs > .item-list > ul li.ui-tabs-active a {
          color: #777;
          background: #fff;
          border-radius: 5px 5px 0px 0px;
          border-color: #ddd !important;
          border-width: 1px 1px 0 1px !important;
          border-style: solid !important;
          padding: 1.3rem 2rem 1.3rem 2rem !important; }
      .panel-primary-care-1col .ui-tabs > .item-list > ul li.ui-state-default {
        background: #FFF;
        padding: 1rem;
        border-width: 0; }
      .panel-primary-care-1col .ui-tabs > .item-list > ul li.ui-state-hover {
        padding: 1rem;
        border-width: 0; }
  .panel-primary-care-1col .ui-tabs .ui-widget-header {
    background: #FFF !important;
    font-family: inherit !important;
    font-size: inherit !important; }
  .panel-primary-care-1col .ui-tabs .ui-widget-content a {
    color: #CF4520; }
    .panel-primary-care-1col .ui-tabs .ui-widget-content a:hover {
      color: #E87722; }
  .panel-primary-care-1col .ui-tabs .ui-tabs-panel {
    padding: 1.2rem 0 !important; }
    .panel-primary-care-1col .ui-tabs .ui-tabs-panel .view-calendar .view-header .date-nav ul.pager li.date-prev,
    .panel-primary-care-1col .ui-tabs .ui-tabs-panel .view-calendar .view-header .date-nav ul.pager li.date-next {
      background: none;
      padding: 0; }

.panel-primary-care-1col .pane-calendar-panel-pane-1,
.panel-primary-care-1col .pane-calendar-panel-pane-2 {
  padding-bottom: 2rem; }
  .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header,
  .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header {
    position: relative; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: initial;
      bottom: -10.5rem; }
      @media screen and (min-width: 768px) {
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper {
          right: 5%;
          top: 0.5rem;
          bottom: initial; } }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper .date-result-message,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper .date-result-message {
        margin: 0 auto;
        font-size: 1rem; }
        @media screen and (min-width: 768px) {
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper .date-result-message,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper .date-result-message {
            font-size: 1.4rem;
            padding-left: 3rem; } }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper {
      position: inherit;
      margin-top: 1.2rem; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .date-heading,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .date-heading {
        text-align: left;
        width: 40%; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .date-heading h3,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .date-heading h3 {
          font-size: 1.5rem; }
          @media screen and (min-width: 768px) {
            .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .date-heading h3,
            .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .date-heading h3 {
              font-size: 2rem; } }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .pager li,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .pager li {
        padding: 0 1rem;
        width: 10rem; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .pager li a,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .pager li a {
          color: #fff;
          background-color: #cf4520;
          border-color: #cf4520;
          width: 10rem; }
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .pager li a:hover,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .pager li a:hover {
            background-color: #E87722;
            color: #fff; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-nav-wrapper .date-nav .pager li.date-prev,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-nav-wrapper .date-nav .pager li.date-prev {
        margin-right: 6rem; }
  .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters,
  .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters {
    position: relative; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters form,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters form {
      clear: both;
      padding-bottom: 5rem;
      margin-top: -2rem; }
      @media screen and (min-width: 768px) {
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters form,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters form {
          position: absolute;
          bottom: 7.5rem;
          right: 0;
          margin: 0; } }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters form .views-exposed-widget,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters form .views-exposed-widget {
        padding: 0; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters form .views-exposed-widget label,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters form .views-exposed-widget label {
          float: left;
          padding: 1rem 1.5rem 0 0; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters form .views-exposed-widget .views-widget,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters form .views-exposed-widget .views-widget {
          float: left; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-filters .select2,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-filters .select2 {
      width: 32rem !important; }
  .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar,
  .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar {
    font-size: 1rem;
    letter-spacing: -1.8px; }
    @media screen and (min-width: 768px) {
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar {
        font-size: 1.3rem;
        letter-spacing: inherit; } }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar th.days,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar th.days {
      background-color: transparent;
      border: none;
      padding: 1rem 0;
      letter-spacing: 0px; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view,
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view {
      min-width: 55rem; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view thead,
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view thead,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view thead,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view thead {
        border-top: 7px solid #ececec; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner,
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner {
        font-size: 1.6rem;
        color: #555; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item,
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item {
          border-left: 5px solid #eab62d;
          background-color: #fffed7;
          min-height: 10rem; }
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item .calendar,
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item .calendar,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item .calendar,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item .calendar {
            padding: 8px;
            background: none;
            font-size: medium;
            overflow: visible; }
            .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item .calendar .views-field,
            .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item .calendar .views-field,
            .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item .calendar .views-field,
            .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item .calendar .views-field {
              font-size: 1.1rem;
              line-height: 1.3; }
              @media screen and (min-width: 768px) {
                .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item .calendar .views-field,
                .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item .calendar .views-field,
                .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item .calendar .views-field,
                .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item .calendar .views-field {
                  font-size: 1.4rem; } }
              .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item .calendar .views-field .field-content .date-display-single,
              .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item .calendar .views-field .field-content .date-display-single,
              .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item .calendar .views-field .field-content .date-display-single,
              .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item .calendar .views-field .field-content .date-display-single {
                font-weight: 100; }
            .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item .calendar .contents,
            .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item .calendar .contents,
            .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item .calendar .contents,
            .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item .calendar .contents {
              font-size: 1.1rem;
              line-height: 1.3;
              position: inherit;
              width: auto;
              background-color: #fffed7;
              left: 0; }
              @media screen and (min-width: 768px) {
                .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .inner .view-item .calendar .contents,
                .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .inner .view-item .calendar .contents,
                .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .inner .view-item .calendar .contents,
                .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .inner .view-item .calendar .contents {
                  font-size: 1.4rem; } }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .cutoff,
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view .cutoff,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .cutoff,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view .cutoff {
        display: none; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view th.calendar-day-holder,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view th.calendar-day-holder {
      border: none; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view th.calendar-agenda-hour,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view th.calendar-agenda-hour {
      border-bottom: none; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.all-day,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.all-day {
      height: 5rem; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.all-day .calendar-agenda-hour,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.all-day .calendar-agenda-hour {
        color: #555;
        border-color: #dcdcdc;
        border-left: 1px solid #dcdcdc; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.all-day .calendar-agenda-hour .calendar-hour,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.all-day .calendar-agenda-hour .calendar-hour {
          font-size: 1rem;
          font-weight: normal;
          padding-right: 2px;
          letter-spacing: 0; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.all-day td.multi-day,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.all-day td.multi-day {
        padding: 4px 0;
        width: 6%; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.all-day td.multi-day .date-display-single,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.all-day td.multi-day .date-display-single {
          display: none; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.all-day td.multi-day a,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.all-day td.multi-day a {
          font-weight: 200;
          letter-spacing: 0; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day {
      border-right: 1px solid #ececec;
      height: 5rem; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day:last-child,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day:last-child {
        border-bottom: 1px solid #dcdcdc; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td {
        color: #555;
        border-color: #dcdcdc;
        letter-spacing: 0;
        padding: 0;
        width: 6%; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td.calendar-agenda-hour,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td.calendar-agenda-hour {
          color: #555;
          border-color: #dcdcdc;
          border-left: 1px solid #dcdcdc; }
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td.calendar-agenda-hour .calendar-hour,
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td.calendar-agenda-hour .calendar-ampm,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td.calendar-agenda-hour .calendar-hour,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td.calendar-agenda-hour .calendar-ampm {
            font-size: 1rem;
            font-weight: normal;
            padding-right: 2px; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td .calendar,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td .calendar {
          padding: 0;
          padding-bottom: 3px; }
          .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td .calendar .item .weekview,
          .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td .calendar .item .weekview {
            margin: 0.4rem auto; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view tr.not-all-day td a,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view tr.not-all-day td a {
          font-weight: 200; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .week-view .full div.single-day div.weekview,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .week-view .full div.single-day div.weekview {
      border: none; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr.multi-day .inner, .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr.single-day .inner,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr.multi-day .inner,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr.single-day .inner {
      min-height: 9rem; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr.multi-day .empty .inner, .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr.single-day .empty .inner,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr.multi-day .empty .inner,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr.single-day .empty .inner {
      background-color: #fdfdfd; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr:last-child,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr:last-child {
      border-bottom: 1px solid #dcdcdc; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td {
      letter-spacing: -0.4px;
      color: #555;
      border-color: #dcdcdc;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      padding: 0; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.today,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.today {
        border-color: #e7781f;
        border-width: 0 4px 4px 4px; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.multi-day,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.multi-day {
        padding-top: 3px; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.multi-day .date-display-single,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.multi-day .date-display-single {
          display: none; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.multi-day, .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.single-day,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.multi-day,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.single-day {
        border-top: 0px !important; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.multi-day.empty, .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td.single-day.empty,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.multi-day.empty,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td.single-day.empty {
          background: #fff;
          color: #ddd; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view tr td a,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view tr td a {
        font-weight: 200; }
    .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view td.date-box,
    .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view td.date-box {
      height: 4rem;
      padding-top: 4px; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view td.date-box.empty,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view td.date-box.empty {
        background-color: #fdfdfd; }
        .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view td.date-box.empty .inner,
        .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view td.date-box.empty .inner {
          color: #eee; }
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-content .calendar-calendar .month-view td.date-box.today,
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-content .calendar-calendar .month-view td.date-box.today {
        border-color: #e7781f;
        border-width: 4px 4px 0 4px; }

.panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper {
  right: 5%; }
  .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper .date-result-message {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .panel-primary-care-1col .pane-calendar-panel-pane-1 .view-header .date-result-wrapper .date-result-message {
        width: 50%; } }

.panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper {
  right: 0; }
  .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper .date-result-message {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .panel-primary-care-1col .pane-calendar-panel-pane-2 .view-header .date-result-wrapper .date-result-message {
        width: 40%; } }

.panel-primary-care-1col .ajax-progress-throbber {
  display: none; }

.panel-primary-care-1col table.table tr th {
  font-size: 1.1rem;
  vertical-align: middle;
  text-align: center;
  padding: 4px; }
  @media screen and (min-width: 768px) {
    .panel-primary-care-1col table.table tr th {
      font-size: 1.3rem; } }
  @media screen and (min-width: 992px) {
    .panel-primary-care-1col table.table tr th {
      font-size: 1.4rem; } }

.panel-primary-care-1col table.table tr td {
  font-size: 1.1rem; }
  @media screen and (min-width: 768px) {
    .panel-primary-care-1col table.table tr td {
      font-size: 1.3rem; } }
  @media screen and (min-width: 992px) {
    .panel-primary-care-1col table.table tr td {
      font-size: 1.4rem; } }

.panel-fullhero-3col .main-content {
  padding-bottom: 4rem; }

.panel-hero-3col #edit-sort-combine {
  float: left; }
  .panel-hero-3col #edit-sort-combine .form-item {
    float: left; }
    .panel-hero-3col #edit-sort-combine .form-item .form-group {
      padding: 0 2rem; }
      .panel-hero-3col #edit-sort-combine .form-item .form-group a {
        color: #000;
        font-size: 1.3rem; }
        .panel-hero-3col #edit-sort-combine .form-item .form-group a:after {
          position: relative;
          top: 3px;
          left: 6px;
          display: inline-block;
          font-family: 'Glyphicons Halflings';
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
        .panel-hero-3col #edit-sort-combine .form-item .form-group a.active {
          color: #e97721; }
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-lastname-asc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-title-asc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-biblio-year-asc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-field-deadline-date-value-asc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-field-trial-primary-investigator-value-asc a:after {
      content: '\e114'; }
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-lastname-desc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-title-desc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-biblio-year-desc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-field-deadline-date-value-desc a:after,
    .panel-hero-3col #edit-sort-combine .form-item #edit-sort-combine-field-trial-primary-investigator-value-desc a:after {
      content: '\e113'; }
  .panel-hero-3col #edit-sort-combine .select2 {
    display: none; }

.panel-hero-3col #edit-sort-combine-wrapper {
  clear: both;
  padding-top: 2.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  width: 100%; }
  .panel-hero-3col #edit-sort-combine-wrapper label {
    float: left;
    padding-right: 1rem;
    padding-top: 0.2rem; }

.panel-hero-3col .calendar-link-container {
  border-width: 3px 0;
  border-color: #ddd;
  border-style: solid;
  padding: 1rem 0 2rem 0; }
  .panel-hero-3col .calendar-link-container .calendar-link-left {
    padding-top: 1rem; }
    @media screen and (min-width: 1200px) {
      .panel-hero-3col .calendar-link-container .calendar-link-left {
        padding-top: 0; } }
    .panel-hero-3col .calendar-link-container .calendar-link-left .calendar-icon {
      position: relative; }
      .panel-hero-3col .calendar-link-container .calendar-link-left .calendar-icon:after {
        content: '';
        position: absolute;
        background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
        background-size: 1920%;
        background-position: -394px -78px;
        width: 65px;
        height: 65px;
        opacity: 1;
        left: 0px;
        top: -3px; }
  .panel-hero-3col .calendar-link-container .calendar-link-right {
    padding-left: 3rem;
    border-left: 3px solid #ddd; }
    .panel-hero-3col .calendar-link-container .calendar-link-right .calendar-link-title {
      font-family: '1898Sans-Bold';
      font-size: 2.2rem; }
      .panel-hero-3col .calendar-link-container .calendar-link-right .calendar-link-title .glyphicon {
        font-size: 1.6rem;
        color: #e7751d; }
    .panel-hero-3col .calendar-link-container .calendar-link-right .calendar-link-body {
      font-size: 1.6rem;
      color: #7a7a7a; }

.panel-hero-3col .pane-news-panel-pane-6 {
  margin-top: 2rem;
  clear: both; }
  .panel-hero-3col .pane-news-panel-pane-6 .pane-title {
    margin-bottom: 0; }
  .panel-hero-3col .pane-news-panel-pane-6 .view-filters .select2 {
    width: 32rem !important; }
  .panel-hero-3col .pane-news-panel-pane-6 div.views-row {
    padding: 2rem 0;
    clear: both;
    position: relative;
    overflow: hidden; }
    .panel-hero-3col .pane-news-panel-pane-6 div.views-row:not(:last-child) {
      border-bottom: 1px solid #ddd; }
    .panel-hero-3col .pane-news-panel-pane-6 div.views-row .teaser-image {
      margin-left: 2.8rem;
      float: right; }
      .panel-hero-3col .pane-news-panel-pane-6 div.views-row .teaser-image img {
        width: auto;
        max-width: 100%; }
    .panel-hero-3col .pane-news-panel-pane-6 div.views-row .teaser-text .post-date {
      color: #626262;
      font-size: 1.3rem;
      padding-top: 0.4remsssss;
      margin-bottom: 0.2rem;
      font-weight: 100; }
    .panel-hero-3col .pane-news-panel-pane-6 div.views-row .teaser-text .news-category {
      padding-top: 0.5rem; }
      .panel-hero-3col .pane-news-panel-pane-6 div.views-row .teaser-text .news-category a {
        position: relative;
        color: #cf4520;
        font-size: 1.3rem;
        background: url(../images/tag.png) 0 0 no-repeat;
        background-size: 16px 16px;
        padding-left: 25px; }
    .panel-hero-3col .pane-news-panel-pane-6 div.views-row h2, .panel-hero-3col .pane-news-panel-pane-6 div.views-row h3, .panel-hero-3col .pane-news-panel-pane-6 div.views-row h4, .panel-hero-3col .pane-news-panel-pane-6 div.views-row h5 {
      margin-top: 0; }
  .panel-hero-3col .pane-news-panel-pane-6 .view-footer {
    float: left;
    padding-top: 1rem; }

.panel-hero-3col .pane-profiles-panel-pane-2 .views-row {
  clear: both; }
  @media screen and (min-width: 768px) {
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row {
      width: 100%;
      height: 24.2rem;
      margin-bottom: 2rem;
      border-width: 1px 1px 1px 1px;
      border-color: #eee;
      border-style: solid; } }
  @media screen and (min-width: 992px) {
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row {
      width: 90%; } }
  @media screen and (min-width: 768px) {
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-image {
      float: left;
      display: inline-block; } }
  .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-image img {
    width: auto;
    max-height: 24rem; }
  .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body {
    position: relative;
    font-size: 1.4rem;
    height: 20rem;
    color: #555;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body {
        display: inline-block;
        height: 24rem;
        width: 65%; } }
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .list-body-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      width: 94%;
      -webkit-transform: translate(0%, -50%);
      -moz-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      -o-transform: translate(0%, -50%);
      transform: translate(0%, -50%); }
      @media screen and (min-width: 768px) {
        .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .list-body-wrapper {
          padding-left: 3rem; } }
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .display-name {
      margin-top: 0; }
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .display-name,
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .item-list,
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .profile-contact {
      border-bottom: 1px solid #eee;
      padding: 0.4rem 0; }
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body .profile-link {
      padding: 0.4rem 0; }
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body p {
      margin: 0; }
    .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body ul {
      list-style: none;
      margin: 0; }
      .panel-hero-3col .pane-profiles-panel-pane-2 .views-row .profile-list-body ul li {
        margin: 0; }

.panel-hero-3col .get-involved-in-research-pane {
  clear: both; }
  .panel-hero-3col .get-involved-in-research-pane p {
    margin: 0; }
  .panel-hero-3col .get-involved-in-research-pane div.row {
    margin: 0;
    padding: 0;
    height: 24.3rem; }
    .panel-hero-3col .get-involved-in-research-pane div.row:nth-child(1) {
      background-color: #cf4520; }
    .panel-hero-3col .get-involved-in-research-pane div.row:nth-child(2) {
      background-color: #b41b1d; }
    .panel-hero-3col .get-involved-in-research-pane div.row:nth-child(3) {
      background-color: #e77723; }
    .panel-hero-3col .get-involved-in-research-pane div.row:nth-child(4) {
      background-color: #cf4520; }
    .panel-hero-3col .get-involved-in-research-pane div.row:nth-child(5) {
      background-color: #b41b1d; }
    .panel-hero-3col .get-involved-in-research-pane div.row:hover div.list-item a.list-image {
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s; }
      .panel-hero-3col .get-involved-in-research-pane div.row:hover div.list-item a.list-image > span img {
        -webkit-filter: grayscale(20%);
        -moz-filter: grayscale(20%);
        -ms-filter: grayscale(20%);
        -o-filter: grayscale(20%);
        filter: grayscale(20%);
        opacity: 0.3; }
      .panel-hero-3col .get-involved-in-research-pane div.row:hover div.list-item a.list-image > span + span {
        opacity: 0.89; }
    .panel-hero-3col .get-involved-in-research-pane div.row:hover div.list-item a.list-title {
      text-decoration: underline; }
    .panel-hero-3col .get-involved-in-research-pane div.row div.list-item {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
      position: relative; }
      .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-title {
        text-align: center;
        text-decoration: none;
        color: #fff;
        height: 100%;
        width: 100%;
        display: table;
        position: absolute; }
        @media screen and (max-width: 767px) {
          .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-title {
            position: unset; } }
        .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-title > span {
          font-family: '1898Sans-Bold';
          font-size: 2.8rem;
          line-height: 1.2;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          padding: 0 5rem; }
          .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-title > span .glyphicon-triangle-right:before {
            font-size: 2rem; }
      .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-image {
        margin: 0 auto;
        position: relative;
        background-color: #333;
        display: block;
        height: 100%;
        width: 100%;
        border-bottom: none; }
        .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-image > span img {
          height: 100%;
          width: auto;
          max-width: none; }
        .panel-hero-3col .get-involved-in-research-pane div.row div.list-item a.list-image > span + span {
          opacity: 0;
          width: 80%;
          position: absolute;
          top: 30%;
          left: 10%;
          color: #fff;
          font-size: 1.8rem;
          font-family: '1898Sans-Regular';
          line-height: 1.4;
          -webkit-transition: opacity 0.5s;
          -moz-transition: opacity 0.5s;
          -ms-transition: opacity 0.5s;
          -o-transition: opacity 0.5s;
          transition: opacity 0.5s; }

.panel-hero-3col .pane-research-studies-panel-pane-1 .views-row .views-field-field-primary-investigator .views-label,
.panel-hero-3col .pane-research-studies-panel-pane-3 .views-row .views-field-field-primary-investigator .views-label,
.panel-hero-3col .pane-research-studies-panel-pane-4 .views-row .views-field-field-primary-investigator .views-label {
  font-weight: 600; }

.panel-hero-3col .pane-research-studies-panel-pane-1 .views-row .views-field-field-primary-investigator .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-3 .views-row .views-field-field-primary-investigator .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-4 .views-row .views-field-field-primary-investigator .field-content {
  display: inline; }

.panel-hero-3col .pane-research-studies-panel-pane-1 .views-row .views-field-field-research-study-area a,
.panel-hero-3col .pane-research-studies-panel-pane-1 .views-row .views-field-field-research-study-status a,
.panel-hero-3col .pane-research-studies-panel-pane-3 .views-row .views-field-field-research-study-area a,
.panel-hero-3col .pane-research-studies-panel-pane-3 .views-row .views-field-field-research-study-status a,
.panel-hero-3col .pane-research-studies-panel-pane-4 .views-row .views-field-field-research-study-area a,
.panel-hero-3col .pane-research-studies-panel-pane-4 .views-row .views-field-field-research-study-status a {
  font-size: 1.3rem !important;
  position: relative;
  color: #cf4520;
  font-size: 13px;
  background: url(../images/tag.png) 0 0 no-repeat;
  background-size: 16px 16px;
  padding-left: 25px; }

.panel-hero-3col .pane-research-studies-panel-pane-1 .views-row .views-field-field-research-study-area .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-1 .views-row .views-field-field-research-study-status .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-3 .views-row .views-field-field-research-study-area .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-3 .views-row .views-field-field-research-study-status .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-4 .views-row .views-field-field-research-study-area .field-content,
.panel-hero-3col .pane-research-studies-panel-pane-4 .views-row .views-field-field-research-study-status .field-content {
  display: inline; }

.panel-hero-3col .pane-research-studies-panel-pane-1 .view-filters,
.panel-hero-3col .pane-research-studies-panel-pane-4 .view-filters {
  padding-bottom: 1rem; }
  .panel-hero-3col .pane-research-studies-panel-pane-1 .view-filters form .select2,
  .panel-hero-3col .pane-research-studies-panel-pane-4 .view-filters form .select2 {
    width: 24rem !important;
    margin-right: 2rem; }

.panel-hero-3col .pane-research-studies-panel-pane-1 div.views-row,
.panel-hero-3col .pane-research-studies-panel-pane-4 div.views-row {
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
  clear: both; }
  .panel-hero-3col .pane-research-studies-panel-pane-1 div.views-row:not(:last-child),
  .panel-hero-3col .pane-research-studies-panel-pane-4 div.views-row:not(:last-child) {
    border-bottom: 1px solid #ddd; }
  .panel-hero-3col .pane-research-studies-panel-pane-1 div.views-row .views-field-field-research-study-area,
  .panel-hero-3col .pane-research-studies-panel-pane-4 div.views-row .views-field-field-research-study-area {
    float: left; }
  .panel-hero-3col .pane-research-studies-panel-pane-1 div.views-row .views-field-field-research-study-status,
  .panel-hero-3col .pane-research-studies-panel-pane-4 div.views-row .views-field-field-research-study-status {
    padding-left: 1.8rem;
    display: inline; }

.panel-hero-3col .pane-research-studies-panel-pane-4 .pane-title {
  font-size: 3rem; }

.panel-hero-3col .pane-research-studies-panel-pane-4 div.view-footer {
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: left; }
  .panel-hero-3col .pane-research-studies-panel-pane-4 div.view-footer a.btn--wcm {
    width: 26rem;
    margin: 0; }

.panel-hero-3col .pane-clinical-trial-panel-pane-1 form .form-type-select .select2 {
  width: 32rem !important; }

.panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content {
  padding-top: 2.5rem; }
  .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    clear: both;
    /*.views-field-title {
          .field-content {
            margin: 0;
            a.external-link {
              &:after {
                padding-top: 0.3rem;
                font-size: 85%;
              }
            }
          }
        }*/ }
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row:not(:last-child) {
      border-bottom: 1px solid #ddd; }
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .trial-title {
      padding-bottom: 10px;
      font-size: 17px; }
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-primary-investigator,
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-name,
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-email,
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-phone,
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field {
      padding-bottom: 0.2rem; }
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-primary-investigator .views-label,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-name .views-label,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-email .views-label,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-phone .views-label,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field .views-label {
        font-weight: 600; }
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-primary-investigator .field-content,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-name .field-content,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-email .field-content,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-field-trial-contact-phone .field-content,
      .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field .field-content {
        display: inline; }
    .panel-hero-3col .pane-clinical-trial-panel-pane-1 .view-content .views-row .views-field-nothing {
      padding-top: 1rem; }

.panel-hero-3col .pane-biblio-views-panel-pane-1 .view-filters {
  padding-bottom: 1rem; }
  .panel-hero-3col .pane-biblio-views-panel-pane-1 .view-filters form .select2 {
    width: 34rem !important; }

.panel-hero-3col .pane-biblio-views-panel-pane-1 .views-row {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  clear: both; }
  .panel-hero-3col .pane-biblio-views-panel-pane-1 .views-row:not(:last-child) {
    border-bottom: 1px solid #ddd; }
  .panel-hero-3col .pane-biblio-views-panel-pane-1 .views-row .views-field-title .field-content {
    margin: 0; }
  .panel-hero-3col .pane-biblio-views-panel-pane-1 .views-row .views-field-field-publication-category a {
    font-size: 1.3rem !important;
    position: relative;
    color: #cf4520;
    font-size: 13px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px; }
  .panel-hero-3col .pane-biblio-views-panel-pane-1 .views-row .views-field-field-publication-category .field-content {
    display: inline; }
  .panel-hero-3col .pane-biblio-views-panel-pane-1 .views-row .views-field-biblio-authors .views-label {
    font-weight: 800; }

.panel-hero-3col .pane-biblio-views-panel-pane-2 .views-row .views-field {
  padding-bottom: 1rem; }
  .panel-hero-3col .pane-biblio-views-panel-pane-2 .views-row .views-field .views-label {
    font-weight: 600; }
  .panel-hero-3col .pane-biblio-views-panel-pane-2 .views-row .views-field .field-content {
    display: inline; }

.panel-hero-3col .pane-biblio-views-panel-pane-2 .views-row .views-field-field-publication-category a {
  font-size: 1.3rem !important;
  position: relative;
  color: #cf4520;
  font-size: 13px;
  background: url(../images/tag.png) 0 0 no-repeat;
  background-size: 16px 16px;
  padding-left: 25px; }

.panel-hero-3col .pane-research-studies-panel-pane-3 .pane-title {
  margin-top: 0;
  color: #000;
  font-size: 2.2rem;
  padding-bottom: 1rem;
  font-family: '1898Sans-Bold'; }

.panel-hero-3col .pane-research-studies-panel-pane-3 div.views-row {
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ddd; }
  .panel-hero-3col .pane-research-studies-panel-pane-3 div.views-row .views-field-title {
    padding-top: 0.5rem; }
    .panel-hero-3col .pane-research-studies-panel-pane-3 div.views-row .views-field-title .field-content {
      line-height: 1.5;
      margin-top: 0; }
      .panel-hero-3col .pane-research-studies-panel-pane-3 div.views-row .views-field-title .field-content a {
        font-size: 1.5rem;
        font-family: '1898Sans-Bold';
        color: #cf4520;
        line-height: 26px; }
        .panel-hero-3col .pane-research-studies-panel-pane-3 div.views-row .views-field-title .field-content a:after {
          padding-left: 6px;
          line-height: 3; }
  .panel-hero-3col .pane-research-studies-panel-pane-3 div.views-row .views-field {
    font-size: 1.3rem; }

.panel-hero-3col .pane-research-studies-panel-pane-3 .view-footer {
  text-align: center;
  padding-top: 1rem;
  margin-top: 1rem; }

.panel-hero-3col .pane-research-studies-panel-pane-3 + .jump-to-top {
  display: none !important;
  opacity: 0 !important; }
  @media screen and (min-width: 992px) {
    .panel-hero-3col .pane-research-studies-panel-pane-3 + .jump-to-top {
      display: block !important;
      opacity: 1 !important; } }

.panel-hero-3col .pane-funding-opportunities-panel-pane-1 .view-filters .select2 {
  width: 32rem !important; }

.panel-hero-3col .pane-funding-opportunities-panel-pane-1 div.views-row {
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ddd; }
  .panel-hero-3col .pane-funding-opportunities-panel-pane-1 div.views-row .views-field-field-deadline-date .views-label {
    font-weight: 600; }
  .panel-hero-3col .pane-funding-opportunities-panel-pane-1 div.views-row .views-field-field-deadline-date .field-content {
    display: inline; }
  .panel-hero-3col .pane-funding-opportunities-panel-pane-1 div.views-row .views-field-field-rfp-study-area a {
    font-size: 1.3rem !important;
    position: relative;
    color: #cf4520;
    font-size: 13px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px; }

.panel-hero-3col .pane-calendar-panel-pane-4 div.views-row {
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ddd; }
  .panel-hero-3col .pane-calendar-panel-pane-4 div.views-row .views-field-field-event-start-date .views-label,
  .panel-hero-3col .pane-calendar-panel-pane-4 div.views-row .views-field-field-event-end-date .views-label {
    font-weight: 600; }
  .panel-hero-3col .pane-calendar-panel-pane-4 div.views-row .views-field-field-event-start-date .field-content,
  .panel-hero-3col .pane-calendar-panel-pane-4 div.views-row .views-field-field-event-end-date .field-content {
    display: inline; }
  .panel-hero-3col .pane-calendar-panel-pane-4 div.views-row .views-field-field-event-category a {
    font-size: 1.3rem !important;
    position: relative;
    color: #cf4520;
    font-size: 13px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px; }

.panel-hero-3col .pane-taxonomy-term .pane-title {
  font-size: 4rem;
  margin: 2rem 0; }

.panel-hero-3col .pane-pals-site-links {
  text-align: center;
  padding-bottom: 2rem;
  width: 100%; }
  .panel-hero-3col .pane-pals-site-links ul {
    list-style: none;
    padding: 0; }
    .panel-hero-3col .pane-pals-site-links ul li {
      margin: 0; }
  .panel-hero-3col .pane-pals-site-links ul {
    margin: 2rem auto; }
    .panel-hero-3col .pane-pals-site-links ul li {
      padding-right: 3rem;
      float: left; }
      .panel-hero-3col .pane-pals-site-links ul li a.external-link {
        padding: 10px 20px 10px 0px !important; }
        .panel-hero-3col .pane-pals-site-links ul li a.external-link:hover:before {
          opacity: 1;
          left: 70%;
          color: #fff; }
        .panel-hero-3col .pane-pals-site-links ul li a.external-link:before {
          left: 70%;
          opacity: 1;
          font-size: 1.5rem;
          line-height: 3;
          color: #e7751d; }
  .panel-hero-3col .pane-pals-site-links .clear-fix {
    clear: both; }

.panel-hero-3col .pane-internal-use-pane.login {
  border-top: 1px solid #ddd;
  margin-top: 1rem; }

.panel-hero-3col .pane-internal-use-pane .view-display-id-panel_pane_1 .view-content {
  padding-top: 0.5rem; }
  .panel-hero-3col .pane-internal-use-pane .view-display-id-panel_pane_1 .view-content h3 {
    text-decoration: underline; }
  .panel-hero-3col .pane-internal-use-pane .view-display-id-panel_pane_1 .view-content .views-row {
    padding-bottom: 0.4rem; }
    .panel-hero-3col .pane-internal-use-pane .view-display-id-panel_pane_1 .view-content .views-row .views-field-title {
      padding-bottom: 1rem; }

.panel-hero-3col .main-content {
  padding-bottom: 4rem; }
  .panel-hero-3col .main-content .pane-entity-field {
    padding-bottom: 1rem; }
    .panel-hero-3col .main-content .pane-entity-field .field-label {
      font-weight: 600;
      display: inline; }
    .panel-hero-3col .main-content .pane-entity-field .field-type-taxonomy-term-reference a {
      font-size: 1.3rem !important;
      position: relative;
      color: #cf4520;
      font-size: 13px;
      background: url(../images/tag.png) 0 0 no-repeat;
      background-size: 16px 16px;
      padding-left: 25px; }
  .panel-hero-3col .main-content .pane-node-field-research-study-area {
    padding-bottom: 0; }
    .panel-hero-3col .main-content .pane-node-field-research-study-area ul.links {
      margin: 0; }

.panel-hero-3col article.node-webform > h2 {
  display: none; }

.panel-hero-3col .pane-bundle-slideshow .cycle-slideshow {
  border: none;
  max-width: 78rem;
  margin-top: 1rem;
  width: 100%;
  background: #f7f7f7 !important;
  /* pager */ }
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-prev,
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-next {
    width: 20px;
    height: 35px;
    opacity: 0.6;
    top: 40%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    cursor: pointer; }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-prev,
      .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-next {
        width: 25px;
        height: 45px;
        top: 45%; } }
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-prev {
    position: absolute;
    left: 0;
    z-index: 101;
    background: url(../images/arrow_left.png) #e87722 no-repeat center center; }
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-next {
    position: absolute;
    right: 0;
    z-index: 101;
    background: url(../images/arrow_right.png) #e87722 no-repeat center center; }
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-prev:hover,
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-next:hover {
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"; }
  .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-pager {
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    z-index: 500;
    display: none; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-pager {
        display: block; } }
    .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-pager > * {
      cursor: pointer; }
    .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-pager span {
      font-family: arial;
      font-size: 30px;
      width: 12px;
      height: 12px;
      display: inline-block;
      color: #ddd; }
      @media screen and (min-width: 768px) {
        .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-pager span {
          font-size: 40px;
          width: 16px;
          height: 16px; } }
    .panel-hero-3col .pane-bundle-slideshow .cycle-slideshow .cycle-pager .cycle-pager-active {
      color: #e87722; }

.no-js .panel-primary-care-home .go-to-next {
  display: none; }

.no-js .panel-primary-care-home #primary-care-header .front-hero-container .container-block {
  cursor: default; }
  .no-js .panel-primary-care-home #primary-care-header .front-hero-container .container-block .slide-item .slide-text:before, .no-js .panel-primary-care-home #primary-care-header .front-hero-container .container-block .slide-item .slide-text:after {
    display: none; }

.no-js .panel-primary-care-home #primary-care-footer .right-bottom-left {
  height: 56rem !important; }
  @media screen and (min-width: 992px) {
    .no-js .panel-primary-care-home #primary-care-footer .right-bottom-left {
      height: 41rem !important; } }
  @media screen and (min-width: 1200px) {
    .no-js .panel-primary-care-home #primary-care-footer .right-bottom-left {
      height: 56rem !important; } }
  .no-js .panel-primary-care-home #primary-care-footer .right-bottom-left iframe {
    background-image: url("../images/primary_care_img_maps.jpg");
    background-size: 100% 100%; }

.no-js .panel-primary-care-home #primary-care-footer .right-bottom-right {
  height: 56rem !important; }
  @media screen and (min-width: 992px) {
    .no-js .panel-primary-care-home #primary-care-footer .right-bottom-right {
      height: 41rem !important; } }
  @media screen and (min-width: 1200px) {
    .no-js .panel-primary-care-home #primary-care-footer .right-bottom-right {
      height: 56rem !important; } }

.no-js #main-content .pane-views-panes .view-filters {
  display: none !important; }

/**
 * Navigation
 */
@media screen and (min-width: 992px) {
  body.page-home #top-nav {
    position: relative;
    background-color: #fff; }
    body.page-home #top-nav:before {
      position: absolute;
      content: '';
      background-color: #cf4520;
      top: 44px;
      width: 100%;
      height: 5px; }
  body.page-home #primary-nav ul {
    border-bottom: none !important; }
  body.page-home #drawer-nav ul {
    padding-top: 5px; } }

#primary-nav ul li.level-1 {
  white-space: nowrap;
  width: auto;
  padding: 1rem 0rem;
  min-width: 15%;
  font-size: 12.5px; }
  @media screen and (min-width: 992px) {
    #primary-nav ul li.level-1 {
      font-size: 15px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav ul li.level-1 {
      font-size: 16px; } }
  #primary-nav ul li.level-1 .active-trail {
    color: #B31B1B; }

#primary-nav ul li:nth-child(2) {
  width: 20%; }

#primary-nav ul li:nth-child(3),
#primary-nav ul li:nth-child(4) {
  width: 25%; }

#drawer-nav ul.menu > li:nth-child(3) ul li.level-2 {
  width: 24%; }
  @media screen and (min-width: 1200px) {
    #drawer-nav ul.menu > li:nth-child(3) ul li.level-2 {
      width: 22%; } }

.panel-student-services-home #student-services-home-header .container-liquid .row,
.panel-student-services-home #student-services-home-bottom .container-liquid .row {
  margin: 0 !important; }

.panel-student-services-home #student-services-home-content-header .panel-pane {
  text-align: center;
  padding-top: 0.5rem;
  color: #555; }
  .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme {
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme {
        float: left;
        width: 33.333333333%; } }
    .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme img {
      max-height: 14rem; }
    .panel-student-services-home #student-services-home-content-header .panel-pane div.floatme p {
      padding-top: 0.8rem;
      margin-bottom: 0;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 1.4rem;
      color: #555;
      border-top: 1px solid #eee;
      margin: 0.6rem 6rem 0.8rem 6rem; }
  .panel-student-services-home #student-services-home-content-header .panel-pane .pane-title {
    font-size: 4rem; }
  .panel-student-services-home #student-services-home-content-header .panel-pane clear {
    line-height: 1; }

.panel-student-services-home #student-services-home-top {
  float: none;
  clear: both;
  background-color: #f8f8f8;
  border-top: 1px solid #eee;
  padding: 3rem 0; }
  .panel-student-services-home #student-services-home-top #top-2col-left {
    padding-left: 10px;
    padding-right: 10px; }
    .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane {
      margin: 0;
      color: #555; }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane .pane-title {
        font-size: 2.2rem;
        margin: 0 0 1rem 0; }
        @media screen and (min-width: 1200px) {
          .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane .pane-title {
            margin: -1px 0 1rem 0;
            font-size: 2.4rem; } }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane img {
        max-width: 98%; }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane p {
        padding-top: 1rem;
        margin: 0; }
      .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div {
        padding: 2rem;
        background: #fff; }
        @media screen and (min-width: 992px) {
          .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div {
            padding: 54px 30px; } }
        @media screen and (min-width: 1200px) {
          .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div {
            padding: 48px 30px; } }
        .panel-student-services-home #student-services-home-top #top-2col-left div.panel-pane > div:hover {
          outline: 3px solid #efefef; }
  .panel-student-services-home #student-services-home-top #top-2col-right {
    padding-left: 10px;
    padding-right: 10px; }
    .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane {
      margin: 0;
      color: #555; }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane:first-child {
        margin-top: 3.8rem;
        margin-bottom: 2rem; }
        @media screen and (min-width: 1200px) {
          .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane:first-child {
            margin-top: 4rem; } }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane > div {
        padding: 2rem;
        background: #fff; }
        @media screen and (min-width: 768px) {
          .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane > div {
            padding: 1rem 3rem; } }
        .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane > div:hover {
          outline: 3px solid #efefef; }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane img {
        max-width: 98%; }
      .panel-student-services-home #student-services-home-top #top-2col-right div.panel-pane p {
        padding-top: 1rem;
        margin: 0; }
    .panel-student-services-home #student-services-home-top #top-2col-right .panels-ipe-sort-container div.panels-ipe-portlet-wrapper:nth-child(2) {
      margin-top: -2rem; }

.panel-student-services-home #student-services-home-footer .container {
  padding: 0; }

.panel-twocol #main-content .field-name-field-news-category .field-label {
  display: none; }

.panel-twocol #main-content .field-name-field-news-category ul.links {
  padding-top: 2rem; }
  .panel-twocol #main-content .field-name-field-news-category ul.links li a {
    position: relative;
    color: #cf4520;
    font-size: 13px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px; }

.panel-twocol #main-content .news-featured-image figure.hero-image img {
  width: auto;
  height: 100%; }

.panel-twocol #main-content .news-featured-image figure.hero-image figcaption {
  float: none;
  text-align: left;
  margin: -1rem 0 1rem 0; }

.information-column .pane-news-panel-pane-9 .pane-title {
  text-align: center;
  font-size: 1.9rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem; }

.information-column .pane-news-panel-pane-9 .view-news .item-list ul {
  display: table;
  margin: 0 auto; }

footer {
  padding: 0;
  clear: both; }
  footer .footer-contact .footer-contact__site-name a {
    font-size: 15px; }
    @media screen and (min-width: 992px) {
      footer .footer-contact .footer-contact__site-name a {
        font-size: 18px; } }
  footer .footer-site .footer-address {
    border-right: none; }
    @media screen and (min-width: 992px) {
      footer .footer-site .footer-address {
        width: 20% !important; } }
  footer .footer-site .footer-nav {
    margin-bottom: 2rem;
    border-left: 1px solid #DDD; }
    @media screen and (min-width: 992px) {
      footer .footer-site .footer-nav {
        width: 78% !important; } }
    footer .footer-site .footer-nav .block {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      -ms-column-count: 3;
      -o-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 2rem;
      -moz-column-gap: 2rem;
      -ms-column-gap: 2rem;
      -o-column-gap: 2rem;
      column-gap: 2rem; }
      footer .footer-site .footer-nav .block ul.menu {
        width: 85%; }
        footer .footer-site .footer-nav .block ul.menu li:nth-child(3) {
          display: inline-block; }

/**
 * global classes
 */
div.content-footer {
  clear: both; }
  @media screen and (min-width: 992px) {
    div.content-footer div.pane-footer-cta-pane {
      border-top: 1.4rem solid #eee;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding-top: 0.1rem; } }
  div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container {
    max-width: 1140px;
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane {
        padding-left: 5%;
        float: left;
        width: 70%; }
        div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane div.footer-cta-tile {
          width: 100%; }
          div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane div.footer-cta-tile a.wcm-cta__tile:not(:last-child) {
            border-width: 0 1px 0 0; }
          div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane div.footer-cta-tile a.wcm-cta__tile:last-child {
            border-width: 0; }
          div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane div.footer-cta-tile a.wcm-cta__tile {
            padding: 0rem 7.8rem;
            margin: 1.5rem 0 0 0; }
            div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane div.footer-cta-tile a.wcm-cta__tile .wcm-cta__detail .cta-icon {
              width: 55px;
              height: 55px; }
            div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-cta-pane div.footer-cta-tile a.wcm-cta__tile span {
              font-size: 1.5rem; }
      div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.primary-care-footer-contact-info {
        padding-top: 1.2rem;
        padding-right: 5%; } }
    div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.jump-to-top {
      display: block !important;
      opacity: 1 !important; }
      @media screen and (min-width: 992px) {
        div.content-footer div.pane-footer-cta-pane div.primary-care-footer-container div.jump-to-top {
          display: none !important;
          opacity: 0 !important; } }

.view-filters form .views-exposed-widget {
  padding: 0; }
  .view-filters form .views-exposed-widget label {
    font-size: 1.3rem; }
  .view-filters form .views-exposed-widget .form-item .select2 .select2-selection--single {
    height: 46px !important; }
    .view-filters form .views-exposed-widget .form-item .select2 .select2-selection--single .select2-selection__rendered {
      line-height: 46px !important;
      color: #777 !important; }

.select2-container {
  color: #777; }

.btn {
  padding: 10px 20px;
  color: #e7751d;
  font-size: 1.4rem; }
  .btn:before {
    line-height: 2.5; }

.webform-submit {
  padding: 6px 20px;
  color: #fff !important; }

.slideup, .slidedown {
  max-height: 0;
  overflow: hidden; }
  .slideup.slidemove, .slidedown.slidemove {
    -webkit-transition: max-height 550ms ease 0s;
    -moz-transition: max-height 550ms ease 0s;
    -ms-transition: max-height 550ms ease 0s;
    -o-transition: max-height 550ms ease 0s;
    transition: max-height 550ms ease 0s; }

.slidedown {
  max-height: 140rem; }
  @media screen and (min-width: 992px) {
    .slidedown {
      max-height: 70rem; } }

.slide-arrow-display:after {
  border-top-width: 5rem !important; }

.slide-arrow-hidden:after {
  border-top: 0 solid transparent !important; }

.glyphicon-triangle-right:before {
  content: "\e250"; }

.glyphicon-triangle-left:before {
  content: '\e251'; }

.glyphicon-triangle-top:before {
  content: '\e253'; }

.glyphicon-triangle-bottom:before {
  content: '\e252'; }

.social-share__email a:before {
  content: '\e80c'; }

.navbar-divider {
  position: relative;
  display: block;
  padding: 10px 0px;
  color: #fff;
  line-height: 2.5; }

a.pc-btn-small {
  display: inline-block;
  background-color: #cf4520;
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  color: #fff;
  margin-right: 10px;
  font-size: 1.3rem; }

.views-field-field-meeting-minutes .file img.file-icon[title="application/pdf"],
.pane-node-field-research-files img.file-icon[title="application/pdf"] {
  display: none; }
  .views-field-field-meeting-minutes .file img.file-icon[title="application/pdf"] + a,
  .pane-node-field-research-files img.file-icon[title="application/pdf"] + a {
    position: relative;
    padding-left: 3.2rem; }
    .views-field-field-meeting-minutes .file img.file-icon[title="application/pdf"] + a:before,
    .pane-node-field-research-files img.file-icon[title="application/pdf"] + a:before {
      content: '';
      position: absolute;
      background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
      background-size: 1620%;
      background-position: -20px -70px;
      width: 24px;
      height: 24px;
      opacity: 1;
      left: 0px;
      top: -3px; }

.views-field-field-meeting-minutes .file img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
.pane-node-field-research-files img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] {
  display: none; }
  .views-field-field-meeting-minutes .file img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] + a,
  .pane-node-field-research-files img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] + a {
    position: relative;
    padding-left: 3.2rem; }
    .views-field-field-meeting-minutes .file img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] + a:before,
    .pane-node-field-research-files img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] + a:before {
      content: '';
      position: absolute;
      background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
      background-size: 1620%;
      background-position: 4px -70px;
      width: 24px;
      height: 24px;
      opacity: 1;
      left: 0px;
      top: -3px; }

.views-field-field-meeting-minutes .file img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"],
.pane-node-field-research-files img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"] {
  display: none; }
  .views-field-field-meeting-minutes .file img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"] + a,
  .pane-node-field-research-files img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"] + a {
    position: relative;
    padding-left: 3.2rem; }
    .views-field-field-meeting-minutes .file img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"] + a:before,
    .pane-node-field-research-files img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"] + a:before {
      content: '';
      position: absolute;
      background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
      background-size: 1620%;
      background-position: -46px -70px;
      width: 24px;
      height: 24px;
      opacity: 1;
      left: 0px;
      top: -3px; }
