.panel-hero-3col {
  #edit-sort-combine {
    float: left;
    .form-item {
      float: left;
      .form-group {
        padding: 0 2rem;
        a {
          color: #000;
          font-size: 1.3rem;
          &:after {
            position: relative;
            top: 3px;
            left: 6px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          &.active {
            color: #e97721;
          }
        }
      }
      #edit-sort-combine-lastname-asc,
      #edit-sort-combine-title-asc,
      #edit-sort-combine-biblio-year-asc,
      #edit-sort-combine-field-deadline-date-value-asc,
      #edit-sort-combine-field-trial-primary-investigator-value-asc {
        a:after {
          content: '\e114';
        }
      }
      #edit-sort-combine-lastname-desc,
      #edit-sort-combine-title-desc,
      #edit-sort-combine-biblio-year-desc,
      #edit-sort-combine-field-deadline-date-value-desc,
      #edit-sort-combine-field-trial-primary-investigator-value-desc {
        a:after {
          content: '\e113';
        }
      }
    }
    .select2 {
      display: none;
    }
  }
  #edit-sort-combine-wrapper {
    clear: both;
    padding-top: 2.5rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
    label {
      float: left;
      padding-right: 1rem;
      padding-top: 0.2rem;
    }
  }

  // news pane
  .calendar-link-container {
    border-width: 3px 0;
    border-color: #ddd;
    border-style: solid;
    padding: 1rem 0 2rem 0;
    .calendar-link-left {
      padding-top: 1rem;
      @include breakpoint($lg) {
        padding-top: 0;
      }
      .calendar-icon {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
          background-size: 1920%;
          background-position: -394px -78px;
          width: 65px;
          height: 65px;
          opacity: 1;
          left: 0px;
          top: -3px;
        }
      }
    }
    .calendar-link-right {
      padding-left: 3rem;
      border-left: 3px solid #ddd;
      .calendar-link-title {
        font-family: '1898Sans-Bold';
        font-size: 2.2rem;
        .glyphicon {
          font-size: 1.6rem;
          color: $wcm-bright-orange;
        }
      }
      .calendar-link-body {
        font-size: 1.6rem;
        color: #7a7a7a;
      }
    }
  }
  .pane-news-panel-pane-6 {
    margin-top: 2rem;
    clear: both;
    .pane-title {
      margin-bottom: 0;
    }
    .view-filters {
      .select2 {
        width: 32rem !important;
      }
    }
    div.views-row {
      padding: 2rem 0;
      clear: both;
      position: relative;
      overflow:hidden;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
      .teaser-image {
        // width: 30%;
        margin-left: 2.8rem;
        float: right;
        img {
          width: auto;
          max-width: 100%;
        }
      }
      .teaser-text {
        .post-date {
          color: #626262;
          font-size: 1.3rem;
          padding-top: 0.4remsssss;
          margin-bottom: 0.2rem;
          font-weight: 100;
        }
        .news-category {
          padding-top: 0.5rem;
          a {
            @include custom-tag-icon(#cf4520, 1.3rem);
          }
        }
      }
      h2, h3, h4, h5 {
        margin-top: 0;
      }
    }
    .view-footer {
      float: left;
      padding-top: 1rem;
    }
  }

  // leadership view pane
  .pane-profiles-panel-pane-2 {
    .views-row {
      clear: both;
      @include breakpoint($sm) {
        width: 100%;
        height: 24.2rem;
        margin-bottom: 2rem;
        border-width: 1px 1px 1px 1px;
        border-color: #eee;
        border-style: solid;
      }
      @include breakpoint($md) {
        width: 90%;
      }
      .profile-list-image {
        @include breakpoint($sm) {
          float: left;
          display: inline-block;
        }
        img {
          width: auto;
          max-height: 24rem;
        }
      }
      .profile-list-body {
        position: relative;
        font-size: 1.4rem;
        height: 20rem;
        color: #555;
        width: 100%;
        @include breakpoint($sm) {
          display: inline-block;
          height: 24rem;
          width: 65%;
        }
        // @include breakpoint($md) {
        //   width: 75%;
        // }
        .list-body-wrapper {
          position: absolute;
          top: 50%;
          left: 0;
          width: 94%;
          @include custom-transform(translate(0%, -50%));
          @include breakpoint($sm) {
            padding-left: 3rem;
          }
        }
        .display-name {
          margin-top: 0;
        }
        .display-name,
        .item-list,
        .profile-contact {
          border-bottom: 1px solid #eee;
          padding: 0.4rem 0;
        }
        .profile-link {
          padding: 0.4rem 0;
        }
        p {
          margin: 0;
        }
        ul {
          list-style: none;
          margin: 0;
          li {
            margin: 0;
          }
        }
      }
    }
  }
  .get-involved-in-research-pane {
    clear: both;
    p {
      margin: 0;
    }
    div.row {
      margin: 0;
      padding: 0;
      height: 24.3rem;
      &:nth-child(1) {
        background-color: #cf4520;
      }
      &:nth-child(2) {
        background-color: #b41b1d;
      }
      &:nth-child(3) {
        background-color: #e77723;
      }
      &:nth-child(4) {
        background-color: #cf4520;
      }
      &:nth-child(5) {
        background-color: #b41b1d;
      }
      &:hover {
        div.list-item {
          a.list-image {
            //background-color: #222;
            @include custom-transition(all 0.5s);
            > span {
              img {
                @include custom-filter(grayscale, 20%);
                opacity: 0.3;
              }
              + span {
                //color: #fff;
                opacity: 0.89;
              }
            }
          }
          a.list-title {
            text-decoration: underline;
          }
        }
      }
      div.list-item {
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: hidden;
        position: relative;
        a.list-title {
          text-align: center;
          text-decoration: none;
          color: #fff;
          height: 100%;
          width: 100%;
          display: table;
          position: absolute;

          @include breakpoint($xs) {
            position: unset;
          }

          > span {
            font-family: '1898Sans-Bold';
            font-size: 2.8rem;
            line-height: 1.2;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            padding: 0 5rem;
            .glyphicon-triangle-right {
              &:before {
                font-size: 2rem;
              }
            }
          }
        }
        a.list-image {
          margin: 0 auto;
          position: relative;
          background-color: #333;
          display: block;
          height: 100%;
          width: 100%;
          border-bottom: none;

          > span {
            img {
              height: 100%;
              width: auto;
              max-width: none;
            }
            + span {
              opacity: 0;
              width: 80%;
              position: absolute;
              top: 30%;
              left: 10%;
              color: #fff;
              font-size: 1.8rem;
              font-family: '1898Sans-Regular';
              line-height: 1.4;
              @include custom-transition(opacity 0.5s);
              // @include breakpoint($lg) {
              //   top: 38%;
              // }
            }
          }
        }
      }
    }
  }
  // Research studies
  .pane-research-studies-panel-pane-1,
  .pane-research-studies-panel-pane-3,
  .pane-research-studies-panel-pane-4 {
    .views-row {
      .views-field-field-primary-investigator {
        .views-label {
          font-weight: 600;
        }
        .field-content {
          display: inline;
        }
      }
      .views-field-field-research-study-area,
      .views-field-field-research-study-status {
        a {
          font-size: 1.3rem !important;
          @include custom-tag-icon;
        }
        .field-content {
          display: inline;
        }
      }
    }
  }
  // Research studies -- list page
  .pane-research-studies-panel-pane-1,
  .pane-research-studies-panel-pane-4 {
    .view-filters {
      padding-bottom: 1rem;
      form {
        .select2 {
          width: 24rem !important;
          margin-right: 2rem;
        }
      }
    }
    div.views-row {
      padding-bottom: 1.5rem;
      margin-bottom: 0.5rem;
      clear: both;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
      .views-field-field-research-study-area {
        float: left;
      }
      .views-field-field-research-study-status {
        padding-left: 1.8rem;
        display: inline;
      }
    }
  }
  .pane-research-studies-panel-pane-4 {
    .pane-title {
      font-size: 3rem;
    }
    div.view-footer {
      padding-top: 2rem;
      padding-bottom: 1rem;
      text-align: left;
      a.btn--wcm {
        width: 26rem;
        margin: 0;
      }
    }
  }
  .pane-clinical-trial-panel-pane-1 {
    form {
      .form-type-select {
        .select2 {
          width: 32rem !important;
        }
      }
    }
    .view-content {
      padding-top: 2.5rem;
      .views-row {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        clear: both;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }

        .trial-title {
          padding-bottom: 10px;
          font-size: 17px;
        }
        /*.views-field-title {
          .field-content {
            margin: 0;
            a.external-link {
              &:after {
                padding-top: 0.3rem;
                font-size: 85%;
              }
            }
          }
        }*/
        .views-field-field-trial-primary-investigator,
        .views-field-field-trial-contact-name,
        .views-field-field-trial-contact-email,
        .views-field-field-trial-contact-phone,
        .views-field {
          padding-bottom: 0.2rem;
          .views-label {
            font-weight: 600;
          }
          .field-content {
            display: inline;
          }
        }
        .views-field-nothing {
          padding-top: 1rem;
        }
      }
    }
  }
  // biblio featured publications pane
  .pane-biblio-views-panel-pane-1 {
    .view-filters {
      padding-bottom: 1rem;
      form {
        .select2 {
          width: 34rem !important;
        }
      }
    }
    .views-row {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      clear: both;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
      .views-field-title {
        .field-content {
          margin: 0;
        }
      }
      .views-field-field-publication-category {
        a {
          font-size: 1.3rem !important;
          @include custom-tag-icon;
        }
        .field-content {
          display: inline;
        }
      }
      .views-field-biblio-authors {
        .views-label {
          font-weight: 800;
        }
      }
    }
  }
  .pane-biblio-views-panel-pane-2 {
    .views-row {
      .views-field {
        padding-bottom: 1rem;
        .views-label {
          font-weight: 600;
        }
        .field-content {
          display: inline;
        }
      }
      .views-field-field-publication-category {
        a {
          font-size: 1.3rem !important;
          @include custom-tag-icon;
        }
      }
    }
  }
  // generic view pane settings
  .pane-research-studies-panel-pane-3 {
    .pane-title {
      margin-top: 0;
      color: #000;
      font-size: 2.2rem;
      padding-bottom: 1rem;
      font-family: '1898Sans-Bold';
    }
    div.views-row {
      padding-bottom: 1.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #ddd;
      .views-field-title {
        padding-top: 0.5rem;
        .field-content {
          line-height: 1.5;
          margin-top: 0;
          a {
            font-size: 1.5rem;
            font-family: '1898Sans-Bold';
            color: #cf4520;
            line-height: 26px;
            &:after {
              padding-left: 6px;
              line-height: 3;
            }
          }
        }
      }
      .views-field {
        font-size: 1.3rem;
      }
    }
    .view-footer {
      text-align: center;
      padding-top: 1rem;
      margin-top: 1rem;
    }
    + .jump-to-top {
      display: none !important;
      opacity: 0 !important;
      @include breakpoint($md) {
        display: block !important;
        opacity: 1 !important;
      }
    }
  }

  .pane-funding-opportunities-panel-pane-1 {
    .view-filters {
      .select2 {
        width: 32rem !important;
      }
    }
    div.views-row {
      padding-bottom: 1.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #ddd;
      .views-field-field-deadline-date {
        .views-label {
          font-weight: 600;
        }
        .field-content {
          display: inline;
        }
      }
      .views-field-field-rfp-study-area {
        a {
          font-size: 1.3rem !important;
          @include custom-tag-icon;
        }
      }
    }
  }

  // event taxonomy term page
  .pane-calendar-panel-pane-4 {
    div.views-row {
      padding-bottom: 1.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #ddd;
      .views-field-field-event-start-date,
      .views-field-field-event-end-date {
        .views-label {
          font-weight: 600;
        }
        .field-content {
          display: inline;
        }
      }
      .views-field-field-event-category {
        a {
          font-size: 1.3rem !important;
          @include custom-tag-icon;
        }
      }
    }
  }
  // taxonomy title
  .pane-taxonomy-term {
    .pane-title {
      font-size: 4rem;
      margin: 2rem 0;
    }
  }
  .pane-pals-site-links {
    text-align: center;
    padding-bottom: 2rem;
    width: 100%;
    @include custom-ul-list-style;
    ul {
      margin: 2rem auto;
      li {
        padding-right: 3rem;
        float: left;
        a.external-link {
          padding: 10px 20px 10px 0px !important;
          &:hover {
            &:before {
              opacity: 1;
              left: 70%;
              color: #fff;
            }
          }
          &:before {
            left: 70%;
            opacity: 1;
            font-size: 1.5rem;
            line-height: 3;
            color: $wcm-bright-orange;
          }
        }
      }
    }
    .clear-fix {
      clear: both;
    }
  }
  .pane-internal-use-pane {
    &.login {
      border-top: 1px solid #ddd;
      margin-top: 1rem;
    }
    .view-display-id-panel_pane_1 {
      .view-content {
        padding-top: 0.5rem;
        h3 {
          text-decoration: underline;
        }
        .views-row {
          padding-bottom: 0.4rem;
          .views-field-title {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  // details page
  .main-content {
    padding-bottom: 4rem; // general page padding bottom
    .pane-entity-field {
      padding-bottom: 1rem;
      .field-label {
        font-weight: 600;
        display: inline;
      }
      .field-type-taxonomy-term-reference {
        a {
          font-size: 1.3rem !important;
          @include custom-tag-icon;
        }
      }
    }
    .pane-node-field-research-study-area {
      padding-bottom: 0;
      ul.links {
        margin: 0;
      }
    }
  }
  article.node-webform {
    > h2 {
      display: none;
    }
  }

  .pane-bundle-slideshow {
    .cycle-slideshow {
      border: none;
      max-width: 78rem;
      margin-top: 1rem;
      width: 100%;
      background: $wcm-bg-gray !important;
      .cycle-prev,
      .cycle-next {
        width: 20px;
        height: 35px;
        opacity: 0.6;
        top: 40%;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
        cursor: pointer;
        @include breakpoint($md) {
          width: 25px;
          height: 45px;
          top: 45%;
        }
      }
      .cycle-prev {
        position: absolute;
        left: 0;
        z-index: 101;
        background: url(../images/arrow_left.png) #e87722 no-repeat center center;
      }
      .cycle-next {
        position: absolute;
        right: 0;
        z-index: 101;
        background: url(../images/arrow_right.png) #e87722 no-repeat center center;
      }
      .cycle-prev:hover,
      .cycle-next:hover {
        opacity: 0.8;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      }

      /* pager */
      .cycle-pager {
        position: absolute;
        bottom: 0px;
        overflow: hidden;
        text-align: center;
        width: 100%;
        z-index: 500;
        display: none;
        @include breakpoint($sm) {
          display: block;
        }
        > * {
          cursor: pointer;
        }
        span {
          font-family: arial;
          font-size: 30px;
          width: 12px;
          height: 12px;
          display: inline-block;
          color: #ddd;
          @include breakpoint($sm) {
            font-size: 40px;
            width: 16px;
            height: 16px;
          }
        }
        .cycle-pager-active {
          color: #e87722;
        }
      }
    }
  }

}
