// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

@import "mixins";
@import "home";
@import "onecol";
@import "fullhero_3col";
@import "hero_3col";
@import "nojs";

/**
 * Navigation
 */
body.page-home {
  @include breakpoint($md) {
    #top-nav {
      position: relative;
      background-color: #fff;
      &:before {
        position: absolute;
        content: '';
        background-color: $wcm-dark-orange;
        top: 44px;
        width: 100%;
        height: 5px;
      }
    }
    #primary-nav {
      ul {
        border-bottom: none !important;
      }
    }
    #drawer-nav {
      ul {
        padding-top: 5px;
      }
    }
  }
}

#primary-nav {
  ul {
    li.level-1 {
      white-space: nowrap;
      width: auto;
      padding: 1rem 0rem;
      min-width: 15%;
      font-size: 12.5px;
      @include breakpoint($md) {
        font-size: 15px;
      }
      @include breakpoint($lg) {
        font-size: 16px;
      }
      .active-trail {
        color: #B31B1B;
      }
    }
    li:nth-child(2) {
      width: 20%;
    }
    li:nth-child(3),
    li:nth-child(4) {
      width: 25%;
    }
  }
}

#drawer-nav {
  ul.menu {
    > li:nth-child(3) {
      ul {
        li.level-2 {
          width: 24%;
          @include breakpoint($lg) {
            width: 22%;
          }
        }
      }
    }
  }
}

.panel-student-services-home {
  #student-services-home-header,
  #student-services-home-bottom {
    .container-liquid {
      .row {
        margin: 0 !important;
      }
    }
  }
  #student-services-home-content-header {
    .panel-pane {
      text-align: center;
      padding-top: 0.5rem;
      color: #555;
      div.floatme {
        @include breakpoint($sm) {
          float: left;
          width: 33.333333333%;
        }
        text-align: center;
        padding-top: 1rem;
        margin-bottom: 2rem;
        img {
          max-height: 14rem;
        }
        p {
          padding-top: 0.8rem;
          margin-bottom: 0;
          font-weight: 800;
          text-transform: uppercase;
          font-size: 1.4rem;
          color: #555;
          border-top: 1px solid #eee;
          margin: 0.6rem 6rem 0.8rem 6rem;;
        }
      }
      .pane-title {
        font-size: 4rem;
      }
      clear {
        line-height: 1;
      }
    }
  }
  #student-services-home-top {
    float: none;
    clear: both;
    background-color: #f8f8f8;
    border-top: 1px solid #eee;
    padding: 3rem 0;
    #top-2col-left {
      padding-left: 10px;
      padding-right: 10px;
      div.panel-pane {
        margin: 0;
        color: #555;
        .pane-title {
          font-size: 2.2rem;
          margin: 0 0 1rem 0;
          @include breakpoint($lg) {
            margin: -1px 0 1rem 0;
            font-size: 2.4rem;
          }
        }
        img {
          max-width: 98%;
        }
        p {
          padding-top: 1rem;
          margin: 0;
        }
        > div {
          padding: 2rem;
          @include breakpoint($md) {
            padding: 54px 30px;
          }
          @include breakpoint($lg) {
            padding: 48px 30px;
          }
          background: #fff;
          &:hover {
            outline: 3px solid #efefef;
          }
        }
      }
    }
    #top-2col-right {
      padding-left: 10px;
      padding-right: 10px;
      div.panel-pane {
        margin: 0;
        color: #555;
        &:first-child {
          margin-top: 3.8rem;
          margin-bottom: 2rem;
          @include breakpoint($lg) {
            margin-top: 4rem;
          }
        }
        > div {
          padding: 2rem;
          @include breakpoint($sm) {
            padding: 1rem 3rem;
          }
          background: #fff;
          &:hover {
            outline: 3px solid #efefef;
          }
        }
        img {
          max-width: 98%;
        }
        p {
          padding-top: 1rem;
          margin: 0;
        }
      }
      // for admin page
      .panels-ipe-sort-container {
        div.panels-ipe-portlet-wrapper:nth-child(2) {
          margin-top: -2rem;
        }
      }
    }
  }
  #student-services-home-footer {
    .container {
      padding: 0;
    }
  }
}

.panel-twocol {
  #main-content {
    .field-name-field-news-category {
      .field-label {
        display: none;
      }
      ul.links {
        padding-top: 2rem;
        li {
          a {
            @include custom-tag-icon();
          }
        }
      }
    }
    .news-featured-image {
      figure.hero-image {
        img {
          width: auto;
          height: 100%;
        }
        figcaption {
          float: none;
          text-align: left;
          margin: -1rem 0 1rem 0;
        }
      }
    }
  }
}

.information-column {
  .pane-news-panel-pane-9 {
    .pane-title {
      text-align: center;
      font-size: 1.9rem;
      margin-top: 3rem;
      margin-bottom: 0.5rem;
    }
    .view-news {
      .item-list {
        ul {
          display: table;
          margin: 0 auto;
        }
      }
    }
  }
}

footer {
  padding: 0;
  clear: both;
  .footer-contact {
    .footer-contact__site-name a {
      font-size: 15px;
      @include breakpoint($md) {
        font-size: 18px;
      }
    }
  }
  .footer-site {
    .footer-address {
      border-right: none;
      @include breakpoint($md) {
        width: 20% !important;
      }
    }
    .footer-nav {
      margin-bottom: 2rem;
      border-left: 1px solid #DDD;
      @include breakpoint($md) {
        width: 78% !important;
      }
      .block {
        @include custom-multi-column;
        @include custom-multi-gap;
        ul.menu {
          width: 85%;
          li:nth-child(3) {
            display: inline-block;
          }
        }
      }
    }
  }
}

/**
 * global classes
 */
div.content-footer {
  clear: both;
  div.pane-footer-cta-pane {
    @include breakpoint($md) {
      border-top: 1.4rem solid #eee;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding-top: 0.1rem;
    }
    div.primary-care-footer-container {
      max-width: 1140px;
      margin: 0 auto;
      @include breakpoint($md) {
        div.primary-care-footer-cta-pane {
          padding-left: 5%;
          float: left;
          width: 70%;
          div.footer-cta-tile {
            width: 100%;
            a.wcm-cta__tile:not(:last-child) {
              border-width: 0 1px 0 0;
            }
            a.wcm-cta__tile:last-child {
              border-width: 0;
            }
            a.wcm-cta__tile {
              padding: 0rem 7.8rem;
              margin: 1.5rem 0 0 0;
              .wcm-cta__detail {
                .cta-icon {
                  width: 55px;
                  height: 55px;
                }
              }
              span {
                font-size: 1.5rem;
              }
            }
          }
        }
        div.primary-care-footer-contact-info {
          padding-top: 1.2rem;
          padding-right: 5%;
        }
      }
      div.jump-to-top {
        display: block !important;
        opacity: 1 !important;
        @include breakpoint($md) {
          display: none !important;
          opacity: 0 !important;
        }
      }
    }
  }
}

// view filter select 2
.view-filters {
  form {
    .views-exposed-widget {
      padding: 0;
      label {
        font-size: 1.3rem;
      }
      .form-item {
        .select2 {
          .select2-selection--single {
            height: 46px !important;
            .select2-selection__rendered {
              line-height: 46px !important;
              color: #777 !important;
            }
          }
        }
      }
    }
  }
}

.select2-container {
  color: #777;
}

.btn {
  padding: 10px 20px;
  color: $wcm-bright-orange;
  font-size: 1.4rem;
  &:before {
    line-height: 2.5;
  }
}

.webform-submit {
  padding: 6px 20px;
  color: #fff !important;
}

.slideup, .slidedown {
  max-height: 0;
  overflow: hidden;
  &.slidemove {
    @include custom-transition(max-height 550ms ease 0s);
  }
}
.slidedown {
  max-height: 140rem;
  @include breakpoint($md) {
    max-height: 70rem;
  }
}
.slide-arrow-display {
  &:after {
    //@include custom-transition(border-top 550ms ease-out);
    border-top-width: 5rem !important;
  }
}
.slide-arrow-hidden {
  &:after {
    //@include custom-transition(border-top 550ms ease-in);
    border-top: 0 solid transparent !important;
  }
}

.glyphicon-triangle-right {
  &:before {
    content: "\e250";
  }
}
.glyphicon-triangle-left {
  &:before {
    content: '\e251';
  }
}
.glyphicon-triangle-top {
  &:before {
    content: '\e253';
  }
}
.glyphicon-triangle-bottom {
  &:before {
    content: '\e252';
  }
}

.social-share__email {
  a {
    &:before {
      content:'\e80c';
    }
  }
}

.navbar-divider {
  position: relative;
  display: block;
  padding: 10px 0px;
  color: #fff;
  line-height: 2.5;
}

a.pc-btn-small {
  display: inline-block;
  background-color: #cf4520;
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  color: #fff;
  margin-right: 10px;
  font-size: 1.3rem;
}

.views-field-field-meeting-minutes .file,
.pane-node-field-research-files {
  // pdf
  img.file-icon[title="application/pdf"] {
    display: none;
    + a {
      position: relative;
      padding-left: 3.2rem;
      &:before {
        content: '';
        position: absolute;
        background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
        background-size: 1620%;
        background-position: -20px -70px;
        width: 24px;
        height: 24px;
        opacity: 1;
        left: 0px;
        top: -3px;
      }
    }
  }
  // doc
  img.file-icon[title="application/vnd.openxmlformats-officedocument.wordprocessingml.document"] {
    display: none;
    + a {
      position: relative;
      padding-left: 3.2rem;
      &:before {
        content: '';
        position: absolute;
        background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
        background-size: 1620%;
        background-position: 4px -70px;
        width: 24px;
        height: 24px;
        opacity: 1;
        left: 0px;
        top: -3px;
      }
    }
  }
  // ppt
  img.file-icon[title="application/vnd.openxmlformats-officedocument.presentationml.presentation"] {
    display: none;
    + a {
      position: relative;
      padding-left: 3.2rem;
      &:before {
        content: '';
        position: absolute;
        background: url(/../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
        background-size: 1620%;
        background-position: -46px -70px;
        width: 24px;
        height: 24px;
        opacity: 1;
        left: 0px;
        top: -3px;
      }
    }
  }
}
