/**
 *
 */
.panel-primary-care-home {
  #primary-care-header {
    background-color: #f5f5f5;
    .container-fluid {
      padding: 0;
    }
    .front-hero-container {
      margin: 0 auto;
      .container-block {
        padding: 0;
        cursor: pointer;
        .container-slide-students,
        .container-slide-professionals,
        .container-slide-patients {
          position: relative;
          height: 40rem;
          z-index: 1;
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -25%;
            margin: 0 auto;
            border-top: 0rem solid transparent;
            border-right: 18rem solid transparent;
            border-bottom: 5rem solid transparent;
            border-left: 18rem solid transparent;
            height: 10rem;
            width: 34rem;
          }
          .slide-image-layover {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.35;
            z-index: 0;
          }
          .slide-layover {
            position: absolute;
            position: absolute;
            height: 12.1rem;
            width: 100%;
            bottom: 0;
            opacity: .85;
            z-index: 2;
          }
          .slide-text {
            position: absolute;
            width: 100%;
            font-family: '1898Sans-Regular';
            font-weight: 600;
            font-size: 3.8rem;
            padding-top: 2rem;
            margin: 2.2rem auto 0 auto;
            top: 69%;
            text-align: center;
            color: #fff;
            background: none;
            opacity: 1;
            z-index: 3;
            &:before {
              content: '';
              position: absolute;
              border-radius: 50%;
              background: url(../images/sprite/sprite.svg) no-repeat;
              overflow: hidden;
              bottom: 69%;
              left: 0;
              right: 0;
              margin: 0 auto;
              //z-index: 0;
              width: 13rem;
              height: 13rem;
              border-style: solid;
            }
            &:after {
              content: '';
              position: absolute;
              border-radius: 50%;
              bottom: 69%;
              left: 0;
              right: 0;
              margin: 0 auto;
              //z-index: 0;
              width: 13rem;
              height: 13rem;
            }
          }
        }
        .container-slide-students {
          background-image: url("/sites/default/files/front_students.jpg");
          @include custom-cover-bg-image;
          background-color: #e36000;
          background-blend-mode: multiply;
          &:after {
            border-top-color: #cf4520;
          }
          .slide-layover {
            background-color: #cf4520;
          }
          .slide-text {
            &:before {
              // border-width: 1rem;
              // background-size: 154rem;
              // background-position: -1345px -179px;
              background-color: #cf4520;
              border-color: #cf4520;
              border-width: 3px;
              background-size: 166rem;
              background-position: -1450px -189px;
            }
            &:after {
              @include custom-transition(transform 0.2s, opacity 0.2s);
              @include custom-transform(scale(.8));
            }
          }
          &:hover {
            .slide-text {
              &:before {
                background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
                background-color: #fff;
                border-color: #cf4520;
                // border-width: 8px;
                // background-size: 159.5rem;
                // background-position: -1395px -183px;
                border-width: 6px;
                background-size: 162rem;
                background-position: -1416px -186px;
              }
              &:after {
                box-shadow: 0px 0px 0px 5px #fff;
                @include custom-transform(scale(1));
                opacity: 1;
              }
            }
          }
        }
        .container-slide-professionals {
          background-image: url("/sites/default/files/front_professionals.jpg");
          @include custom-cover-bg-image;
          background-color: #f3bd00;
          background-blend-mode: multiply;
          &:after {
            border-top-color: #ffc82d;
          }
          .slide-layover {
            background-color: #ffc82d;
          }
          .slide-text {
            &:before {
              // border-width: 1rem;
              // background-size: 147.5rem;
              // background-position: -449px -168px;
              background-color: #ffc82d;
              border-color: #ffc82d;
              border-width: 3px;
              background-size: 166rem;
              background-position: -505px -189px;
            }
            &:after {
              @include custom-transition(transform 0.2s, opacity 0.2s);
              @include custom-transform(scale(.8));
            }
          }
          &:hover {
            .slide-text {
              &:before {
                background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
                background-color: #fff;
                border-color: #ffc82d;
                // border-width: 8px;
                // background-size: 159.5rem;
                // background-position: -488px -184px;
                border-width: 6px;
                background-size: 162rem;
                background-position: -494px -186px;
              }
              &:after {
                box-shadow: 0px 0px 0px 5px #fff;
                @include custom-transform(scale(1));
                opacity: 1;
              }
            }
          }
        }
        .container-slide-patients {
          background-image: url("/sites/default/files/front_patients.jpg");
          @include custom-cover-bg-image;
          background-color: #f26f00;;
          background-blend-mode: multiply;
          &:after {
            border-top-color: $wcm-bright-orange;
          }
          .slide-layover {
            background-color: $wcm-bright-orange;
          }
          .slide-text {
            &:before {
              // border-width: 1rem;
              // background-size: 147.5rem;
              // background-position: -1194px -264px;
              background-color: $wcm-bright-orange;
              border-color: $wcm-bright-orange;
              border-width: 3px;
              background-size: 166rem;
              background-position: -1345px -298px;
            }
            &:after {
              @include custom-transition(transform 0.2s, opacity 0.2s);
              @include custom-transform(scale(.8));
            }
          }
          &:hover {
           .slide-text {
              &:before {
                background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
                background-color: #fff;
                border-color: $wcm-bright-orange;
                // border-width: 8px;
                // background-size: 159.5rem;
                // background-position: -1296px -290px;
                border-width: 6px;
                background-size: 162rem;
                background-position: -1315px -291px;;
              }
              &:after {
                box-shadow: 0px 0px 0px 5px #fff;
                @include custom-transform(scale(1));
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .front-hero-container {
      &.mobile {
        @include breakpoint($md) {
          display: none;
        }
      }
    }
    .front-hero-container {
      &.desktop {
        display: none;
        @include breakpoint($md) {
          display: block;
        }
      }
    }
  }
  .pane-front-hero-pane {
    clear: both;
    .pane-title {
      font-family: '1898Sans-Regular', sans-serif;
      font-size: 8.2rem;
      font-weight: 800;
      color: #ddd;
      text-align: left;
      padding: 2.4rem 0;
    }
    .front-header-pane-container {
      &.slidedown {
        @include breakpoint($md) {
          max-height: 52rem;
          height: 52rem;
        };
      }
    }
  }
  #header_pane_students,
  #header_pane_professionals,
  #header_pane_patients {
    position: relative;
    .front-header-pane-title {
      font-size: 6rem;
      font-family: '1898Sans-Bold';
      color: #cdcdcd;
      text-align: center;
      padding: 3.5rem 0 0 0;
      @include breakpoint($md) {
        font-size: 8rem;
        padding: 2.5rem 0 0 0;
      }
    }
    .front-header-pane-subtitle {
      text-align: center;
      font-size: 2.4rem;
      margin-top: -4rem;
      color: #777;
      font-weight: 100;
    }
    .front-header-pane-body {
      width: 100%;
      margin: 0 auto;
      padding: 1.5rem 0 5.5rem 0;
    }
    form#available-funding-form,
    form#search-research-study-form {
      .form-item {
        margin-bottom: 2rem;
        label {
          display: block;
          font-size: 1.6rem;
          color: #777;
          font-weight: 400;
          padding-top: 1.5rem;
        }
      }
      .select2 {
        width: 30rem !important;
        text-align: left;
        @include breakpoint($sm) {
          width: 36rem !important;
        }
        .select2-selection--single {
          height: 50px !important;
          .select2-selection__rendered {
            line-height: 50px !important;
            color: #777 !important;
          }
        }
      }
      .search-more {
        padding-top: 2rem;
      }
    }
  }
  #header_pane_students,
  #header_pane_professionals {
    div.front-page-content {
      &:not(:first-child) {
        padding-top: 3rem;
        @include breakpoint($md) {
          padding-top: 0;
        }
      }
      &:first-child {
        border-right: 1px solid #e8e8e8;
      }
      &:last-child {
        margin: 0 auto;
      }
      .front-page-content-title {
        font-size: 2.2rem;
        padding-top: 1rem;
        padding-bottom: 0.2rem;
        color: #777;
        font-weight: 600;
        padding-top: 0;
      }
    }
  }
  #header_pane_students {
    &.slidedown {
      border-bottom: 5px solid #cf4520;
    }
    &.slideup {
      border: none;
    }
    div.front-page-content {
      &:last-child {
        text-align: center;
      }
    }
    .view {
      .view-content {
        .views-field-title {
          a {
            font-size: 2rem;
          }
        }
        .views-field-field-primary-investigator {
          .views-label {
            font-weight: 600;
          }
          .field-content {
            display: inline-block;
          }
        }
        .views-field-field-research-study-area,
        .views-field-field-research-study-status {
          display: inline-block;
          .field-content {
            a {
              @include custom-tag-icon;
            }
          }
        }
        .views-field-body {
          padding-bottom: 1rem;
        }
        .views-field-field-research-study-status {
          padding-left: 2rem;
        }
      }
    }
  }
  #header_pane_professionals {
    &.slidedown {
      border-bottom: 5px solid #ffc82d;
    }
    &.slideup {
      border: none;
    }
    div.front-page-content {
      text-align: center;
      .professionals-content-container {
        font-size: 2.2rem;
        font-weight: 600;
        color: #777;
        width: 100%;
        div.professionals-content {
          width: 78%;
          margin: 0 auto;
          line-height: 1.4;
          a {
            margin-top: 1rem;
          }
          &:nth-child(2) {
            position: relative;
            padding-top: 2.5rem;
            &:before {
              content: '';
              background: #eee;
              position: absolute;
              height: 1px;
              top: 18px;
              left: 0;
              right: 0;
              width: 60%;
              height: 1px;
              margin: 0 auto;
            }
          }
        }
        p {
          padding-bottom: 0.8rem;
        }
      }
    }
  }
  #header_pane_patients {
    &.slidedown {
      border-bottom: 5px solid $wcm-bright-orange;
    }
    &.slideup {
      border: none;
    }
    div.front-page-content {
      text-align: center;
      color: #777;
      &:not(:first-child) {
        padding-top: 3rem;
        @include breakpoint($md) {
          padding-top: 0;
        }
      }
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #e8e8e8;
      }
      &:last-child {
        margin: 0 auto;
      }
      .front-page-content-title {
        font-size: 2.2rem;
        font-weight: 600;
        width: 100%;
        height: 7rem;
        line-height: 1.4;
      }
      .patients-content-container {
        font-size: 1.6rem;
        text-align: center;
        div.patients-content {
          margin: 0 auto;
          width: 85%;
          a {
            margin-top: 3rem;
          }
          a.external-link {
            padding: 10px 20px 10px 0px !important;
            &:hover {
              &:before {
                opacity: 1;
                left: 70%;
                color: #fff;
              }
            }
            &:before {
              left: 70%;
              opacity: 1;
              font-size: 1.5rem;
              line-height: 3;
              color: $wcm-bright-orange;
            }
          }
        }
      }
    }
  }
  // group setting
  #primary-care-content-footer,
  .pane-calendar-panel-pane-3,
  .pane-news-panel-pane-5 {
    .pane-title {
      font-family: '1898Sans-Bold', sans-serif;
      font-size: 6rem;
      color: #ddd;
      text-align: left;
      padding: 2.4rem 0 0 0;
      @include breakpoint($md) {
        font-size: 8rem;
      }
    }
    .view-header {
      margin-top: 2rem;
    }
  }
  .primary-care-content-top {
    padding: 1.5rem 0 5.5rem 0rem;
    position: relative;
    background-color: #fff;
    &:after {
      content: '';
      background: $wcm-bright-orange;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 26%;
      height: 1px;
      margin: 0 auto;
    }
    a.go-to-next {
      bottom: -21px;
    }
  }
  .pane-calendar-panel-pane-3 {
    .pane-title {
      padding: 0 0 2rem 0;
    }
    .view {
      position: relative;
      .view-header {
        position: absolute;
        top: -8rem;
        right: 0;
        @include breakpoint($md) {
          top: -9rem;
        }
        a {
          font-size: 1.3rem;
          color: $wcm-bright-orange;
          &:before {
            font-size: 1.3rem;
          }
          .glyphicon-triangle-right {
            &:before {
              font-size: 1rem;
            }
          }
        }
      }
      .view-content {
        clear: both;
        // -webkit-transform: translate3d(0, 0, 0);
        .views-row {
          width: 90%;
          position: relative;
          text-align: center;
          padding-bottom: 2rem;
          margin: 0 auto;
          @include breakpoint($sm) {
            width: 50%;
          }
          @include breakpoint($md) {
            width: 22.4%;
            float: left;
            margin-right: 3rem;
          }
          @include breakpoint($lg) {
            width: 23%;
          }
          &.views-row-last {
            @include breakpoint($md) {
              margin-right: 0;
            }
          }
          .front-event-flip {
            //cursor: pointer;
            height: 38rem;
            transition: all 0.3s ease-in;
            transform-style: preserve-3d;
            -webkit-perspective: 1500px; /* Chrome, Safari, Opera */
            perspective: 1500px;
            font-size: 13px;
            @include breakpoint($lg) {
              font-size: 15px;
            }
            &:hover {
              .front-event-flip-front {
                text-decoration: none;
                @include breakpoint($md) {
                  transform: rotateY(180deg);
                }
                .field-event-title h4,
                .field-event-category {
                  text-decoration: none;
                }
              }
              .front-event-flip-back {
                @include breakpoint($md) {
                  transform: rotateY(0deg);
                }
              }
            }
            .front-event-flip-front,
            .front-event-flip-back {
              width: 100%;
              height: 100%;
              transition: all 0.3s ease-in;
	            transform-style: preserve-3d;
              -webkit-backface-visibility: hidden;
              -moz-backface-visibility: hidden;
              -ms-backface-visibility: hidden;
              backface-visibility: hidden;
              //-webkit-font-smoothing: antialiased;
              position: absolute;
              top: 0;
              left: 0;
            }
            .front-event-flip-front {
              z-index: 2;
              transform: rotateY(0deg);
              background-color: #fff;
              a {
                outline: none;
                .field-event-title {
                  min-height: 11rem;
                  h4 {
                    color: #cf4520; // $wcm-bright-orange;
                    line-height: 1.5;
                    font-size: 2rem;
                  }
                }
                &:after {
                  display: none;
                }
              }
              a[target=_blank] {
                .field-event-title {
                  h4:after {
                    font-size: 85%;
                    padding-left: 5px;
                    vertical-align: middle;
                    line-height: 1.2;
                    content: '\e811';
                    font-family: "fontello";
                    font-style: normal;
                    font-weight: normal;
                    speak: none;
                    display: inline-block;
                    text-decoration: inherit;
                    text-align: center;
                    font-variant: normal;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-decoration: none;
                  }
                }
              }
              .field-event-start-date {
                position: absolute;
                width: 8rem;
                top: 14%;
                left: 0;
                right: 0;
                margin: 0 auto;
                border-radius: 50%;
                line-height: 0;
                background: transparent;
                color: #fff;
                border: 2px solid #fff;
                z-index: 2;
                @include breakpoint($lg) {
                  top: 16.5%;
                }
                .event-start-date {
                  display: inline-block;
                  font-family: '1898Sans-Regular', sans-serif;
                  font-size: 3.8rem;
                  margin-top: 2.4rem;
                  padding: 0.8rem 1.2rem 1.6rem 1.2rem;
                }
                .event-start-month {
                  font-size: 1.8rem;
                  display: block;
                  margin-bottom: 2.2rem;
                  padding-top: 0.6rem;
                }
              }
              .field-event-image {
                z-index: 1;
                overflow: hidden;
                vertical-align: middle;
                background-color: $wcm-dark-orange;
                opacity: 1;
                border-radius: 5px;
                max-height: 18rem;
                height: 18rem;
                width: auto;
                @include breakpoint($lg) {
                  height: 20rem;
                  max-height: 20rem;
                }
                img {
                  opacity: 0.3;
                  min-height: 18rem;
                  width: 100%;
                  height: auto;
                  z-index: 0;
                  @include breakpoint($lg) {
                    min-height: 20rem;
                  }
                }
              }
              .field-event-title,
              .field-event-category {
                text-align: left;
              }
              .field-event-category {
                padding-top: 0;
                margin-top: 0.5rem;
                @include custom-tag-icon($wcm-dark-orange);
                &:hover {
                  text-decoration: none;
                }
              }
            }
            .front-event-flip-back {
              z-index: 1;
              background-color: #f5f5f5;
              border-radius: 5px;
              text-align: left;
              padding: 2rem;
              @include breakpoint($md) {
                transform: rotateY(180deg);
              }
              > a {
                @include custom-tag-icon;
              }
              .field-event-body {
                padding-bottom: 1rem;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
              }
              .field-event-start-date,
              .field-event-start-time,
              .field-event-location {
                position: relative;
                padding-bottom: 1.5rem;
                padding-left: 2.9rem;
                &:before {
                  content: '';
                  position: absolute;
                  background: url(../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
                  background-size: 50rem;
                  width: 32px;
                  height: 32px;
                  left: -5px;
                  top: -2px;
                }
              }
              .field-event-start-date {
                margin-top: 2.5rem;
                &:before {
                  background-position: -157px -30px;;
                }
              }
              .field-event-start-time {
                &:before {
                  background-position: -409px -30px;
                }
              }
              .field-event-location {
                &:before {
                  background-position: -377px -30px;
                }
              }
              .event-external-link {
                position: absolute;
                bottom: 3rem;
                @include breakpoint($lg) {
                  bottom: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .primary-care-content-body {
    background-color: #fcfcfc;
    padding-bottom: 6rem;
    .pane-news-panel-pane-5 {
      .pane-title {
        @include breakpoint($md) {
          white-space: nowrap;
        }
      }
      .view {
        .view-header {
          position: relative;
          a {
            position: absolute;
            bottom: 4rem;
            right: 0;
            font-size: 1.3rem;
            color: $wcm-bright-orange;
            @include breakpoint($md) {
              display: none;
            }
            &:after {
              font-size: 1.3rem;
            }
            .glyphicon-triangle-right {
              &:before {
                font-size: 1rem;
              }
            }
          }
        }
        .view-teaser {
          .teaser-image {
            img {
              width: 100%;
            }
          }
          .post-date {
            padding-top: 1.5rem;
            font-size: 1.3rem;
            color: #888;
          }
          .teaser-title {
            line-height: 1.3;
            font-family: '1898Sans-Bold';
            a {
              color: #cf4520;
              &:hover {
                color: $wcm-bright-orange;
              }
              &:after {
                content: '';
              }
            }
          }
          .views-field-field-news-category {
            a {
              @include custom-tag-icon;
            }
          }
        }
      }
    }
  }
  #primary-care-content-footer {
    background-color: #fcfcfc;
    position: relative;
    &:before {
      content: '';
      background: $wcm-bright-orange;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 26%;
      height: 1px;
      margin: 0 auto;
    }
    .pane-title {
      padding-bottom: 0;
      margin-bottom: -0.5rem;
    }
    a.go-to-next {
      top: -23px;
    }
  }
  // mosaic footer
  #primary-care-footer {
    clear: both;
    .container-fluid,
    .row,
    .col-md-12,
    .col-md-6,
    p {
      padding: 0;
      margin: 0;
    }
    .pane-front-footer-pane {
      max-width: 144rem;
      margin: 0 auto;
      .front-footer-pane-container {
        margin: 0;
        padding: 0;
        clear: both;
        .left-top,
        .left-bottom-left,
        .left-bottom-right,
        .right-top-left,
        .right-top-right {
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
            @include custom-transition(all 0.3s ease 0s);
            margin-bottom: -1px;
            &:hover {
              @include custom-transform(scale(1.1));
            }
          }
        }
        .right-container {
          .right-bottom-left {
            iframe {
              width: 100%;
              height: 100%;
            }
          }
          .right-bottom-right {
            height: 100%;
            background: #cf4520;
            color: #fff;
            font-size: 1.8rem;
            font-weight: 100;
            line-height: 1.5;
            padding: 8rem 2.4rem;
            text-align: center;

            @include breakpoint($md) {
              text-align: left;
              padding: 12rem 2.4rem;
            }
            h2 {
              padding-bottom: 1rem;
              color: #fff;
            }
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  a.go-to-next {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-decoration: none;
    border-radius: 50%;
    padding: 2rem 1.65rem;
    line-height: 0;
    background: #fff;
    color: #fff;
    border: 2px solid $wcm-bright-orange;
    width: 44px;
    z-index: 1;
    outline: none;
    &:hover {
      background: $wcm-bright-orange;
      &:after {
        color: #fff;
      }
    }
    &:after {
      content: '\25bc';
      display: block;
      color: $wcm-bright-orange;
      font-weight: bold;
      font-size: 8px;
      text-align: center;
      @include custom-transform(scale(1.8, 1));
    }
  }
}
#body-2col-right {
  padding: 0;
  @include breakpoint($md) {
    padding: 0 2rem 0 2rem;
  }
  .pane-news-panel-pane-7 {
    padding-top: 1rem;
    @include breakpoint($md) {
      padding-top: 10rem;
    }
    .view {
      position: relative;
      .view-header {
        position: absolute;
        top: 0;
        right: 0;
        a {
          font-size: 1.3rem;
          color: $wcm-bright-orange;
          display: none;
          @include breakpoint($md) {
            display: block;
          }
          &:after {
            font-size: 1.3rem;
          }
          .glyphicon-triangle-right {
            &:before {
              font-size: 1rem;
            }
          }
        }
      }
      .view-content {
        clear: both;
        padding-top: 1rem;
        @include breakpoint($md) {
          padding-top: 4.5rem;
        }
        .view-teaser {
          padding-bottom: 1rem;
          .post-date {
            font-size: 1.3rem;
            color: #888;
          }
          .teaser-title {
            font-family: '1898Sans-Bold';
            line-height: 1.3;
            a {
              color: #cf4520;
              &:hover {
                color: $wcm-bright-orange;
              }
              &:after {
                content: '';
              }
            }
          }
        }
      }
      .views-field-field-news-category {
        a {
          @include custom-tag-icon;
        }
      }
    }
  }
}
